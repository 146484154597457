Vue.component('page-receipt', {

    components: {
    },

    data() {
        return {
            reservationDisplayed: apr.reservationDisplayed
        }
    },

    mounted() {

        $('[data-toggle="popover"]').popover({
            html: true,
        });

    },
});
