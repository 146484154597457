
Vue.component('page-psf-airport', {

    data: function () {
        return {
            airport: apr.content ? apr.content.content : ''
        }
    },

    mounted() {

    },

    methods: {
        ecommercePsfTracking(hotelName, index) {
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': 'PSF Search Results'},
                        'products': [{
                            'name': `PSF ${this.airport.code} ${hotelName}`,
                            'id': this.airport.code,
                            'price': '',
                            'brand': '',
                            'category': '',
                            'variant': 'available',
                            'position': index
                        }]
                    }
                }
            })
        },
    }
});
