<template>
    <div>
        <div class="product-modal-slider-wrapper">
            <slick-carousel
                ref="mainSlider"
                class="product-modal-slider"
                :options="mainSliderOptions"
                @beforeChange="syncSliders"
            >
                <slot name="mainSlider"></slot>
            </slick-carousel>
            <button data-toggle="modal" :data-target="modalTarget" class="product-modal-slider-expand">
                <i class="fa fa-expand"></i>
            </button>
            <div class="product-modal-slider-numbers"> {{ currentSlide }} / {{ totalSlidesCount }} </div>
        </div>

        <slick-carousel
            ref="previewSlider"
            class="product-preview-slider"
            :options="previewSliderOptions"
            @beforeChange="syncSliders"
            @click.native="goToSlide"
        >
            <slot name="previewSlider"></slot>
        </slick-carousel>
    </div>
</template>

<script>
import Slick from 'vue-slick'
export default {
    components: {
        'slick-carousel': Slick,
    },
    props: {
        totalSlidesCount: {
            type: Number,
            required: true
        },
        modalTarget: {
            type: String
        }
    },
    data: function () {
        return {
            mainSliderOptions: {
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: false,
                asNavFor: this.$refs.previewSlider,
            },
            previewSliderOptions: {
                autoplay: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                focusOnSelect: true,
                swipeToSlide: true,
                asNavFor: this.$refs.mainSlider,
            },
            currentSlide: 1
        }
    },
    methods: {
        syncSliders(event, slick, currentPosition, nextPosition) {
            this.setActiveSlide(nextPosition)
        },
        goToSlide(e) {
            const target = e.target
            const targetSlide = target.closest('[data-slick-index]')
            if (targetSlide) {
                const targetIndex = targetSlide.getAttribute('data-slick-index')
                if (targetIndex) {
                    this.setActiveSlide(targetIndex)
                }
            }
        },

        setActiveSlide(index) {
            this.$refs.mainSlider.goTo(index)
            this.$refs.previewSlider.goTo(index)

            this.currentSlide = +index + 1;

            const previewSlides = this.$refs.previewSlider.$el.querySelectorAll('.slick-slide')
            previewSlides.forEach((slide) => {
                slide.classList.remove('slick-current')
            })
            this.$refs.previewSlider.$el.querySelector(`[data-slick-index="${index}"]`).classList.add('slick-current')
        },
    },
}
</script>

<style lang="scss">
$white: #fff;
$text-primary-asphalt: #0e242b;
$text-secondary-grey: #56666b;
$text-titanium: #fbfcf9;
$text-titanium-alpha: rgba(251, 252, 249, 0.6);
$brand-primary-500: #0a475a;
$brand-primary-light-30: #3b6c7b;
$brand-secondary-500: #b4e076;
$brand-secodnary-light-30: #c3e691;
$surface-brand: #e6edee;
$surface-brand-light: #F5F8F8;
$surface-brand-super-light: #F5F8F8;
$text-link: #1776ce;
$neutral-mid-grey: #b7bdbf;
$neutral-dark-grey: #869195;
$neutral-light-grey: #e7e9ea;
$neutral-soft-grey: #cfd3d5;
$status-error-red-100: #d41818;
$status-success-green-100: #90c652;
$accent-signal: #de3d68;
$accent-spotlight: #ffbc00;
$status-success-text: #38831e;
$base-borders: #e9e9f0;

.product-modal-slider-wrapper {
    position: relative;
}

.product-modal-slider-numbers,
.product-modal-slider-expand {
    position: absolute;
    bottom: 8px;
    border-radius: 4px;
    background-color: rgba($text-primary-asphalt, 0.5);
    color: $text-titanium;
    z-index: 30;
}

.product-modal-slider-expand {
    left: 8px;
    border: none;
    padding: 3px 8px;
}

.product-modal-slider-numbers {
    padding: 4px 8px;
    right: 8px;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
}

.product-modal-slider {
    position: relative;
    z-index: 10;
    .slick-slide {
        max-height: 67vw !important;
        img {
            max-height: 67vw;
        }
    }
    .slick-prev,
    .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        background-color: rgba($text-primary-asphalt, 0.5);
        z-index: 3;
        border-radius: 50%;
        &:hover {
            background-color: rgba($text-primary-asphalt, 0.5);
        }
        &.slick-disabled {
            opacity: 0.9;
            pointer-events: none;
            background-color: rgba($text-primary-asphalt, 0.5);
        }
        &:before {
            content: '';
            width: 14px;
            height: 16px;
            display: block;
            opacity: 1;

        }
    }
    .slick-prev {
        left: 8px;
        &:before {
            margin-right: 5px;
            background-image: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999999 10L4.48046 6.66667L6.93031 4.32039L11.4414 4.85949e-07L14.9219 3.33333L10.4277 7.63754L17 7.63754L17 12.3463L10.4277 12.3463L14.9219 16.6667L11.4414 20L6.93031 15.6796L4.48047 13.3333L0.999999 10Z' fill='%23FBFCF9'/%3E%3C/svg%3E%0A");
        }
    }
    .slick-next {
        right: 8px;
        &:before {
            margin-left: -1px;
            background-image: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 10L14.5195 13.3333L12.0697 15.6796L7.55861 20L4.07814 16.6667L8.57233 12.3625H2L2 7.65372L8.57233 7.65372L4.07814 3.33333L7.55861 0L12.0697 4.32039L14.5195 6.66667L18 10Z' fill='%23FBFCF9'/%3E%3C/svg%3E%0A");
        }
    }
}

.product-preview-slider {
    position: relative;
    padding: 4px 2px;
    background: $text-primary-asphalt;
    z-index: 10;
    .slick-slide {
        padding: 0 2px;
        min-height: 18vw;
        & > div {
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid $text-titanium;
                opacity: 0;
            }
        }
        &.slick-current > div:after {
            opacity: 1;
        }
    }
}

.product-preview-slider,
.product-modal-slider {
    .slick-track {
        display: flex;
        align-items: stretch;
    }
    .slick-slide {
        height: auto;
        & > div,
        img {
            height: 100%;
        }
        img {
            position: relative;
            width: 100%;
            object-fit: cover;
            object-position: center;
            background-color: #eee;
        }
    }
}
</style>
