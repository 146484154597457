<template>
    <div id="searchbox">
        <div class="searchbox">

            <!-- FIELDS -->
            <form action="" method="post" autocomplete="off" ref="searchboxForm">

                <option-apr v-if="currentTab == 'parking'"></option-apr>
                <option-psf v-else-if="currentTab == 'hotel'"></option-psf>

            </form>

        </div>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin";
    import RequestMixin from "./Requests.js";
    import OptionAPR from "./options/OptionsAPR.vue";
    import OptionPSF from "./options/OptionsPSF.vue";
    import OptionSF from "./options/OptionsSF.vue";
    import DisplayAPR from "./display/DisplayAPR.vue";
    import DisplayPSF from "./display/DisplayPSF.vue";

    export default {
        mixins: [ SearchboxMixin, RequestMixin ],
        props: [ 'hotel' ],
        components: {
            'option-apr': OptionAPR,
            'option-psf': OptionPSF,
            'option-sf': OptionSF,
            'display-apr': DisplayAPR,
            'display-psf': DisplayPSF,
        },

        data(){
            return {
                type: 'searchbox-hotel',
                isEditMode: false,
                showDates: false,
            }
        },

        watch:{
            'query.date1': function(val) {
                this.showDates = true;
            },
        },

        mounted() {
            this.initSearchboxForm()
            this.initSearchboxTooltips()

            if (_.get(window, 'apr.psf_search_results.search')) {
                this.setData(_.get(window, 'apr.psf_search_results.search'))

            } else if (_.get(window, 'apr.hotel')) { // we don't have a search yet, so showing opened searchbox
                // this.isEditMode = true;
                // this.isDefaultMode = true;

                this.$store.dispatch('updateAirport', _.get(window, 'apr.hotel.airport'))
            }
        },

        methods: {
            setData(data) {
                let search = {
                    id: apr ? apr.token : '',
                    airport: {
                        city: apr.hotel ? apr.hotel.airport.name : data.airport.name,
                        code: apr.hotel ? apr.hotel.airport.code : data.airport.code,
                        type: apr.hotel ? apr.hotel.airport.type : data.airport.type,
                        latitude: apr.hotel ? apr.hotel.airport.latitude : data.airport.latitude,
                        longitude: apr.hotel ? apr.hotel.airport.longitude : data.airport.longitude,
                    },
                    date1: data.date1,
                    date2: data.date2,
                    dates: data.dates,
                    hotel_id: apr.hotel ? apr.hotel.hotel_id : 0,
                    package: data.package,
                    guests: data.guests,
                    rooms: data.rooms
                };
                let tab = this.passedOption == 'APR' ? 'parking' : 'hotel';
                this.$store.dispatch('updateTab', tab)
                this.$store.dispatch('updateSession', search);
            },

            setTab(tab) {
                if (tab == 'parking') {
                    this.$store.dispatch('updatePackage', 'APR');
                } else {
                    this.$store.dispatch('updatePackage', this.session.package);
                }
                this.$store.dispatch('updateTab', tab);
            },

            getPageHeading(tagName) {
                if (this.currentTab == 'hotel') {
                    return this.headings.psf[tagName]
                }
                if (this.currentTab == 'shuttle') {
                    return this.headings.sf[tagName]
                }
                return this.headings.apr[tagName]
            },

            showDatesInit(){
                setTimeout(() => {
                    $('input[name="checkindate"]').click()
                }, 10)
            },
        }
    };
</script>
