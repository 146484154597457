import InfoTabs from '../components/NewTemplate/InfoTabs';
import BaseSlider from '../components/NewTemplate/BaseSlider';
import ClosestLots from '../components/NewTemplate/ClosestLots';
import AirportFaq from '../components/NewTemplate/AirportFaq';
import SubscribeSection from '../components/NewTemplate/SubscribeSection';

Vue.component('page-new-landing', {
    components: {
        InfoTabs,
        BaseSlider,
        ClosestLots,
        AirportFaq,
        SubscribeSection
    },
});
