import Datepicker from "./../../components/datepicker";
import AlgoliaTypeahead from "../../components/Searchbox/components/AlgoliaTypeahead";

Vue.component('page-help-cancellation', {
    components: {AlgoliaTypeahead},
    mixins: [Datepicker],

    data() {
        return {
            airportcode: null,
            parkinglots: {},

            parkinglot_id: '',
            reservation_id: null,
            checkindate: null,

            successMessage: null,
            errorMessage: null,
        }
    },

    mounted() {
        let $field = $(this.$el).find("input[name='checkindate']")
        this.initDatepicker($field)

        this.initForm()
    },

    computed: {},

    watch: {},

    methods: {
        setDate(date, fieldName) {
            this.$set(this, fieldName, date)
        },

        setAirport(airport) {
            this.airportcode = airport.code
            this.updateParkingLots()
        },

        initForm() {
            let self = this
            let requesting = false;
            let $form = $(this.$el).find("form")

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {

                if (requesting) {
                    return false;
                }
                requesting = true;
                self.successMessage = null
                self.errorMessage = null

                self.$http.post('/help/cancellation', self.getParams()).then(response => {
                    dataLayer.push({'event': 'GaEvent', 'EventCategory':'Customer', 'EventAction': 'Cancel Reservation'})
                    self.successMessage = response.data.message
                    self.scrollTop()

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.errorMessage = self.filters.formatMessage(errors[errorName])
                            self.scrollTop()
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getParams() {
            return {
                parkinglot_id: this.parkinglot_id,
                reservation_id: this.reservation_id,
                checkindate: this.checkindate,
            };
        },

        updateParkingLots() {
            this.$http.get('/suggest/parkinglots/' + this.airportcode).then(response => {
                this.parkinglots = response.data
                this.parkinglot_id = ''

            }, response => {
                this.parkinglots = {}
                this.parkinglot_id = ''
            });
        },

        scrollTop() {
            $('html, body').animate({scrollTop: 0}, 500);
        }
    }
})
