<template>
    <div class="typeahead">
        <div class="label" v-if="value && isShuttlefinder">{{ title }}</div>
        <input
            type="text"
            class="searchbox-input"
            :placeholder="placeholder"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            name="algolia-typeahead"
            v-model="value"
            @click="openModal()"
            readonly
        />

        <div class="typeahead-modal" v-if="showModal">
            <div class="typeahead-modal-header">
                <input
                    type="text"
                    id="typeahead_input"
                    :name="variable"
                    :class="[variable, 'form-control', hasItems ? 'has-items' : '']"
                    :placeholder="placeholder"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    v-model="query"
                    @keydown.down="down"
                    @keydown.up="up"
                    @keydown.enter.prevent="hit"
                    @mouseenter="isHovered = true"
                    @mouseleave="isHovered = false"
                    @input="update"
                    data-parsley-required-message="Please enter an address or airport."
                />

                <div class="status-icons">
                    <i class="far fa-sync-alt fa-spin" v-if="loading"></i>
                    <i class="typeahead-modal-reset apr-icon-close" @click.prevent="reset(true)" v-if="!loading && query">CLEAR</i>
                    <i class="typeahead-modal-close apr-icon-close" @click.prevent="showModal = false"></i>
										<i class="typeahead-modal-search apr-icon-search-line"></i>
                </div>
            </div>

            <div class="typeahead-list-sf" v-if="hasItems">
                <template v-for="groupItems in itemsByGroup">
                    <div class="typeahead-list-category">{{ groupItems.type }}</div>
                    <div
                        class="typeahead-list-item"
                        v-for="item in groupItems.items"
                        :class="activeClass(item.index)"
                        @mousedown="hit"
                        @mousemove="setActive(item.index)"
                        @mouseover="setActive(item.index)"
                    >
                        <div class="dd-icon-container">
                            <i :class="getIconClass(item)"></i>
                        </div>
                        <span v-html="getNameForDropdown(item)"></span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
let algoliasearch = require('algoliasearch')
import VueTypeahead from 'vue-typeahead'
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
    extends: VueTypeahead,

    props: {
        variable: {
            type: String,
            required: true,
        },
        isShuttlefinder: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            default: 'all',
        },
        hitsPerPage: {
            type: Number,
            default: 6,
        },
        title: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'Enter airport, place, or address',
        },
    },

    data() {
        return {
            el: null,

            items: [],
            query: '',
            value: '',
            current: -1,

            algolia: null,

            apiService: null,
            apiSessionToken: null,

            isFocused: false,
            isHovered: false,
            poweredByGoogle: false,

            showModal: false,
        }
    },

    mounted() {
        let self = this
        this.el = $(`#${this.id}`)

        // algolia
        let client = algoliasearch('228S4IFKXY', '71306311e775ccf0d9496a5b6709cda0')
        this.algolia = client.initIndex('sf_tech')

        // google
        VueGoogleMaps.loaded.then(() => {
            this.apiService = new google.maps.places.AutocompleteService()
            this.apiSessionToken = new google.maps.places.AutocompleteSessionToken()
        })

        // pre-selected
        if (this.isShuttlefinder) {
            this.query = this.formatQuery(this.$parent.$parent.shuttlefinder[this.variable])
        } else {
            this.query = this.formatQuery(this.$parent[this.variable])
        }
    },

    computed: {
        itemsByGroup() {
            let index = 0
            return _.chain(this.items)
                .groupBy((x) => x.type)
                .map((values, key) => ({
                    // group and adding index to each item
                    type: key,
                    items: _.chain(values)
                        .map((value, key) => {
                            value.index = index++
                            return value
                        })
                        .value(),
                }))
                .value()
        },
    },

    watch: {
        '$parent.$parent.shuttlefinder': {
            handler() {
                // set from session
                if (this.isShuttlefiner) {
                    this.query = this.formatQuery(this.$parent.$parent.shuttlefinder[this.variable])
                    this.value = this.query
                }
            },
            deep: true,
        },

        items: function (items) {
            if (!this.items.length) {
                this.poweredByGoogle = false
            } else if (
                items &&
                _.findIndex(items, function (o) {
                    return o.provider === 'google'
                }) !== -1
            ) {
                this.poweredByGoogle = true
            } else {
                this.poweredByGoogle = false
            }
        },
    },

    methods: {
        update: _.debounce(function () {
            if (!this.query) {
                return this.reset(false)
            }

            if (this.query.length < 3) {
                return
            }

            this.fetchFromAll()
        }, 100),

        fetchFromAll() {
            this.fetchAlgolia(this.query)
        },

        fetchAlgolia(searchQuery) {
            let self = this

            let options = {
                query: searchQuery,
                hitsPerPage: this.hitsPerPage,
                // aroundLatLngViaIP: true,
                // aroundRadius: 'all'
            }
            if (this.isOnlyAirports()) {
                options.filters =
                    '(type:airport OR type:cruiseport) AND active:true AND (hierarchy.country.code:US OR hierarchy.country.code:CA)'
            }

            // if (this.isOnlyAddress()) {
            //     options.filters = '(NOT type:airport AND NOT type:cruiseport) AND (hierarchy.country.code:US OR hierarchy.country.code:CA)'
            // }

            this.algolia.search(options).then((content) => {
                if (this.isOnlyAirports()) {
                    if (content.nbHits === 0) {
                        self.items = []
                    } else {
                        self.items = content.hits
                    }
                } else {
                    self.fetchGooglePredictions(searchQuery, content.hits)
                }
            })
        },

        isOnlyAirports() {
            return this.type === 'airport'
        },

        isOnlyAddress() {
            return this.type === 'address'
        },

        fetchGooglePredictions(searchQuery, alreadyFoundAlgoliaItems) {
            let self = this

            this.apiService.getPlacePredictions(
                {
                    // types: ['(cities)'],
                    input: searchQuery,
                    componentRestrictions: { country: 'us' },
                    sessionToken: this.apiSessionToken,
                },
                function (predictions, status) {
                    self.resetSoft()

                    let items = alreadyFoundAlgoliaItems
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        predictions.forEach(function (prediction) {
                            items.push({
                                types: prediction.types,
                                type: self.getTypeFromPrediction(prediction.types),
                                provider: 'google',
                                description: prediction.description,
                                place_id: prediction.place_id,
                            })
                        })
                    }

                    if (searchQuery === self.query) {
                        items = self.excludeDuplicatesFromResults(items)

                        self.items = items
                    } else {
                        self.fetchFromAll()
                    }

                    self.current = -1
                }
            )
        },

        openModal() {
            this.showModal = true
            setTimeout(() => {
                this.focusModalInput()
            }, 100)
        },

        focusModalInput() {
            $(`input[name="${this.variable}"]`).focus()
        },

        getNameForDropdown(item) {
            if (item.provider === 'google') {
                // google
                return item.description
            }

            // return item.display.short // algolia
            return item._highlightResult.display.short.value
        },

        getTypeFromPrediction(types) {
            if (_.indexOf(types, 'transit_station') !== -1) {
                return 'address'
            }
            if (_.indexOf(types, 'point_of_interest') !== -1 || _.indexOf(types, 'natural_feature') !== -1) {
                return 'point of interest'
            }
            if (_.indexOf(types, 'establishment') !== -1) {
                return 'place'
            }
            if (_.indexOf(types, 'locality') !== -1) {
                return 'city'
            }
            if (_.indexOf(types, 'route') !== -1) {
                return 'address'
            }
            return 'address'
        },

        getIconClass(result) {
            return {
                'fas fa-plane': result.type === 'airport',
                'fas fa-ship': result.type === 'cruiseport',
                'fas fa-city': result.type === 'city',
                'fa fa-map-marker-alt': result.type === 'address' || result.type === 'place',
                'fas fa-landmark': result.type === 'landmark' || result.type === 'point of interest',
            }
        },

        excludeDuplicatesFromResults(items) {
            return _.chain(items).uniqBy('place_id').value()
        },

        onHit(item) {
            this.items = []

            if (item.provider === 'google') {
                // address
                this.query = _.get(item, 'description')

                this.setLocationInStore({
                    place_id: _.get(item, 'place_id', null),
                    name: this.query,
                    formatted_address: _.get(item, 'description', null),
                    type: 'address',
                    airport_code: null,
                })
            } else if (_.indexOf(['airport', 'cruiseport'], item.type) !== -1) {
                // airport & cruiseport
                this.query = item.display.full

                this.setLocationInStore({
                    place_id: item.place_id,
                    name: this.query,
                    formatted_address: item.formatted_address,
                    type: item.type,
                    airport_code: _.get(item, 'hierarchy.airport_code', null),
                })
            }

            this.value = this.query

            if ($(this.$el).find('.searchbox-input').parsley()) {
                $('.' + this.variable).removeClass('error')
                $(this.$el).find('.searchbox-input').parsley().reset()
            }

            this.$store.dispatch('updateTripType')

            this.$root.$emit('searchbox::place-selected', {
                place_id: _.get(item, 'place_id'),
                type: _.get(item, 'type'),
                variable: this.variable,
            })

            this.emit()

            setTimeout(() => {
                this.showModal = false
            }, 100)

            $('[name=datetimeDate]').focus()
        },

        reset(focus) {
            this.resetSoft()
            if (focus) {
                this.focusModalInput()
            }
            this.query = ''

            this.$store.dispatch('update' + this.variable.capitalize(), {
                place_id: null,
                name: null,
                formatted_address: null,
                type: null,
                airport_code: null,
            })

            $(this.$el).find('input').focus()
        },

        resetSoft() {
            this.items = []
        },

        focus() {
            this.isFocused = true

            $(this.$el).find('.input-group').removeClass('is-invalid')
        },

        blur() {
            this.isFocused = false
            this.resetSoft()
        },

        emit() {
            if (this.isShuttlefinder) {
                this.$emit('input', this.$parent.$parent.shuttlefinder[this.variable].name)
            } else {
                this.$emit('input', this.$parent[this.variable])
            }
        },

        setLocationInStore(address) {
            if (this.isShuttlefinder) {
                this.$store.dispatch('update' + this.variable.capitalize(), address)
            } else {
                this.$parent[this.variable] = address
            }
        },

        formatQuery(item) {
            return item ? this.getFormattedResult(item) : ''
        },

        getFormattedResult(item) {
            if (_.indexOf(['airport', 'cruiseport'], item.type) !== -1) {
                return item.name || ''
            }

            if (_.startsWith(item.formatted_address, item.name)) {
                return item.formatted_address
            }

            if (item.formatted_address) {
                return `${item.name}, ${item.formatted_address}`
            }
        },
    },
}
</script>
