import "../../../vendor/js/jquery.raty";

Vue.component('page-survey', {
    data() {
        return {
            rating: '',
            title: '',
            shuttle: '',
            service: '',
            condition: '',
            location: '',
            comments: '',
            ugc_answers: {},

            successMessage: null,
            errorMessage: null,
        }
    },

    mounted() {
        this.ratingsStars()
        this.ratingsRectangle()
        this.initForm()
    },

    methods: {
        ratingsStars() {
            let self = this

            $(".raty").raty({
                target: "#hint",
                targetScore: "#rating",
                hints: ["Poor", "Fair", "OK", "Good", "Excellent"],
                score: function () {
                    return $(this).attr("data-score");
                },
                starOff: "/img/icons/star-off.png",
                starOn: "/img/icons/star-on.png"
            });

            $(".raty img").on("click", function () {
                self.rating = $("#rating").val()

                setTimeout(()=> {
                    $("#rating").parsley().validate()
                }, 50)
            })
        },

        ratingsRectangle() {
            let self = this

            $(".level li").click(function () {
                var $li = $(this);

                var $input = $li.parent().next("div").find("input");
                // $input.val($li.data("value"));

                self.$set(self, $input.attr("name"), $li.data("value"))

                setTimeout(()=> {
                    $input.parsley().validate()
                }, 50)


                $li.siblings().removeClass("active").data("selected", false);
                $li.data("selected", true);
                $li.addClass("active").prevAll().addClass("active");
            });

            // ratings: hover
            $(".level li").hover(function () {
                var $li = $(this);
                var $all = $li.parent().children("li");
                $all.removeClass("active");
                $li.addClass("active").prevAll().addClass("active");

            }, function () {
                var $li = $(this);
                var $all = $li.parent().children("li");
                var $selected = null;
                $.each($all, function () {
                    if ($(this).data("selected") == true) {
                        $selected = $(this);
                    }
                });

                if ($selected) {
                    $all.removeClass("active");
                    $selected.addClass("active").prevAll().addClass("active");
                } else {
                    $li.removeClass("active").prevAll().removeClass("active");
                }
            });
        },

        initForm() {
            let self = this
            let requesting = false;
            let $form = $(this.$el).find("form")
            if ($form.length == 0) {
                return;
            }

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {

                if (requesting) {
                    return false
                }
                requesting = true
                self.successMessage = null
                self.errorMessage = null

                self.trackLotReview()

                self.$http.post(apr.submitUrl, self.getParams()).then(response => {
                    self.successMessage = response.data.message
                    requesting = false;

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'error') {
                            self.errorMessage = errors[errorName]
                        }

                        let $field = $form.find(`input[name="${errorName}"]`)

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]})
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getParams() {
            return {
                rating: this.rating,
                title: this.title,
                shuttle: this.shuttle,
                service: this.service,
                condition: this.condition,
                location: this.location,
                comments: this.comments,
                ugc_answers: _.transform(this.ugc_answers, (r, v, k) => {
                    r[k.replace('ugc_', '')] = v;
                }),
            };
        },

        trackLotReview: function () {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'ParkingLotReview',
                'EventAction': 'review submitted'
            });
        }
    }
});
