import Stars from "./../components/stars";
import Datepicker from '../components/VueDatePicker/DatePicker.vue';
import Heading from "../components/heading";
import LightBox from 'vue-image-lightbox'
import ProductImagesSlider from "../components/ProductImagesSlider.vue";
import ExpandableContent from "../components/ExpandableContent.vue";
import BaseSlider from '../components/NewTemplate/BaseSlider.vue';
import Modal from '../components/BsModal.vue'
import Helpers from "../../../shared/helpers";
import AccordionInfo from "../../../shared/js/components/AccordionInfo.vue";

Vue.component('page-lot', {
    components: {
        AccordionInfo,
        Datepicker,
        Stars,
        LightBox,
        ProductImagesSlider,
        Modal,
        ExpandableContent,
        BaseSlider
    },
    mixins: [Heading, Helpers],

    data: function () {
        return {
            checkin_value: '',
            checkout_value: '',
            carousel_slide: 1,
            expandPhotos: false,

            // lot_list: {},
            soldOut: false,
            step: 1,

            showAllPhotos: false,
            alternatives: [],
			alternatives_count: apr.alternatives_count,

            // markers: [],

            search: {
                checkindate: {},
                checkoutdate: {},
                checkintime: {
                    default: ''
                },
                checkouttime: {
                    default: ''
                }
            },
            name: apr.content.parkinglot.name,
            rate: apr.content.parkinglot.rate,
			token: apr.token,
            content: apr.content,
            parkinglot: apr.content.parkinglot,
            options: apr.content.parkinglot.options,
            searchbox: null,
            working: false,
            reviewLoading: false,
            products: [],
            product: null, // selected product_id
			showOffCanvas: false,
	        showSafetyMeasures: false,
            images: [],

            // cloneed form checkout
            errorTimes: null,
            errorTimesMessage: '',
            errorCheckInTime: null,
            errorCheckOutTime: null,
            errorNoTimes: null,
            warningShuttleTimes: false,
            warningShuttleTimesMessage: '',
            searchPermitted: false,
            productsSliderKey: 0,

            times: apr.times,

            isUpdateTimesInProgress: false,
            viewItemRegistered: false
        }
    },

    mounted() {
        let self = this

        this.alternatives = apr.content.alternatives
	    // this.initSearchboxDatepickers($('.checkout'))
        this.initPagination()
        // this.initMap()
        this.initImages()

        // this.scrollStick();
        this.trackLogoClick();

        $('body').scrollspy({target: '#navbar-sticky', offset:100})

        this.$root.$on('session-received', function (data) {
            if (data.searchbox) {
	            if(self.parkinglot.service && !self.soldOut || (!self.parkinglot.service && !self.parkinglot.on_airport)) {
	                self.searchbox = data.searchbox
	                self.checkin_value = data.searchbox.checkindate
	                self.checkout_value = data.searchbox.checkoutdate
		            self.initDateComponent(data)
	            }
            } else {
                self.searchPermitted = true
                self.working = false
            }
        })

        $('#carousel-placeholder').remove();
        $('#carousel-example-generic').on('slid.bs.carousel', function (slide) {
            self.carousel_slide = slide.to + 1;
        });
        $('.carousel-slide-number').html(this.carousel_slide);

        if (apr.show_datepicker) {
            this.focusDate();
        }

        this.$nextTick(function () {
            dataLayer.push({
                'event' : 'airportCodeDetails',
                'airportCode': `${this.content.airport.code}`,
            });

            //dataLayer - productDetailImpressions
            let providerName = this.content.parkinglot.provider;
            let category = providerName === 'PSF'
                    ? "Hotel & Parking Package"
                    : "";
            dataLayer.push({
                'event': 'productDetailImpressions',
                'ecommerce': {
                    'detail': {
                        'actionField': {'list': 'Apparel Gallery'},
                        'products': [{
                            'name': `${this.content.airport.code} ${self.name}`,
                            'id': `${this.content.airport.code}`,
                            'price': self.rate,
                            'brand': '',
                            'category': category,
                            'variant': ''
                        }]
                    }
                }
            })
        });

        $(document).on('click', '.open-gallery', function () {
            self.openGallery(self.carousel_slide - 1);
        });
        $(document).on('click', '.view-operations-hours', function () {
            self.registerEvent('View operation hours clicked');
        });
    },

    computed: {
        searchLink() {
            if (this.searchbox) {
                return `/search/${this.content.airport.code}/?token=${this.searchbox.token}`
            } else if (this.search.token) {
                return `/search/${this.content.airport.code}/?token=${this.search.token}`
            }
        },

	    dates: {
		    get() {
			    if(this.checkin_value || this.checkout_value) {
				    return {
					    date1: moment(this.checkin_value).format('ddd MMM DD YYYY'),
					    dateISO1: moment(this.checkin_value).toDate(),
					    date2: moment(this.checkout_value).format('ddd MMM DD YYYY'),
					    dateISO2: moment(this.checkout_value).toDate()
				    };
			    }else{
				    return {
					    date1: null,
					    dateISO1: null,
					    date2: null,
					    dateISO2: null
				    };
			    }
		    },
		    set(val) {
			    this.checkin_value = val.dateISO1
			    this.checkout_value = val.dateISO2
		    }
	    },

		recommendedLots() {
			return this.alternatives.filter((alternative) => alternative.provider === 'APR').slice(0, 9);
		},

		recommendedHotels() {
			return this.alternatives.filter((alternative) => alternative.provider === 'PSF').slice(0, 9);
		},

        selectedProduct() {
            return this.products.find(product => product.id === this.product);
        },
    },

    watch: {
        'searchbox.token': function (token) {
            if (token) {
                let url = `/search/${this.content.airport.code.toLowerCase()}?token=${token}`;
                $('#navbar .back-button').attr('href', url);
            }
        },

        'parkinglot.baseRateDiscount': function (val) {
            if (val) {
                $('.base-rate-discount').show();
                $('.base-rate-discount span').html(val)
            } else {
                $('.base-rate-discount').hide();
            }
        },

        carousel_slide(val) {
            $('.carousel-slide-number').html(val);
        },

        soldOut(val) {
            if (val) {
                $('.sold-out').show();
            }
        },

        checkout_value() {
            if (this.checkin_value && this.searchPermitted) {
                this.updateSearchDates()
            }
        },
    },

    methods: {
        trackLogoClick() {
            $(document).on('click', '#navbar .logo', () => {
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': 'Logo click',
                    'EventAction': 'Click mobile',
                    'EventLabel': ''
                })
            })
        },

        initPagination() {
            let page = this.getQueryVariable('reviews')

            if (page) {
                this.paginationLink(window.location.href, false)
            } else {
                this.appendPaginationLinks()
            }
        },

        initDateComponent(data) {
            this.working = true
            this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${data.searchbox.token}`, {
                checkindate: this.searchbox.checkindate,
                checkoutdate: this.searchbox.checkoutdate,
            }).then((response) => {
                if(response.body) {
                    this.alternatives = response.body.alternatives
                    this.parkinglot = response.body.parkinglot
                    this.airportCode = response.body.search.data.airport.data.code
                    this.options = response.body.parkinglot.options
                    this.search = response.body.search.data
                    this.products = response.body.parkinglot.products.data
                }
                this.working = false
                this.searchPermitted = true
                this.setSelectPlaceholders();
                this.registerPriceComparisonEvent();
                this.registerViewItemEvent();
                this.productsSliderKey += 1;

            }, (response) => {
                this.soldOut = true;
                this.working = false;

                dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Lot Unavailable'})
            })
        },

        initImages(){
            let self = this
            let mobile_url = this.parkinglot.media.photos.mobile.substring(0, this.parkinglot.media.photos.mobile.length - 1)
            let normal_url = this.parkinglot.media.photos.normal
            let photos = this.parkinglot.media.photos.data[0]

            photos.forEach((photo) => {
                self.images.push({
                    thumb: mobile_url + photo,
                    src: normal_url + photo,
                })
            })
        },

        scrollStick() {
            $(window).scroll(function() {
                var height = $(window).scrollTop();
                var navbarComp = $('.navbar-container').offset();
                if(height > navbarComp.top - 50) {
                    $('#navbar-sticky').addClass('sticky')
                }else{
                    $('#navbar-sticky').removeClass('sticky')
                }
            });
        },

        carouselSlide(dir){
            if(dir === "right"){
                $('.carousel').carousel('prev')
            }else{
                $('.carousel').carousel('next')
            }
        },

        lightboxSlide(dir){
            if(dir === "right"){
                this.$refs.lightbox.nextImage()
            }else if(dir === "left"){
                this.$refs.lightbox.previousImage()
            }else if(dir === "up" || dir === "down"){
                this.$refs.lightbox.closeLightBox()
            }
        },

        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        },

        showDateFormat(type){
            if(type == 'checkin'){
                return moment(this.checkin_value, 'MMM DD, YYYY').format('ddd, MMM D');
            }else if(type == 'checkout'){
                return moment(this.checkout_value, 'MMM DD, YYYY').format('ddd, MMM D');
            }
        },

        updateSearchDates() {
            this.working = true
            dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Calendar dates edited'})
	        if(!this.parkinglot.service && this.parkinglot.on_airport){
		        return this.searchParking()
	        }
            if (this.search.token) {
                this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${this.search.token}`, {
                    checkindate: this.checkin_value,
                    checkoutdate: this.checkout_value,
                }).then((response) => {
                    this.alternatives = response.body.alternatives
                    this.options = response.body.parkinglot.options
                    this.parkinglot = response.body.parkinglot
                    this.airportCode = response.body.search.data.airport.data.code
                    this.working = false
                    this.search = response.body.search.data
                    this.setSelectPlaceholders()
	                this.registerPriceComparisonEvent();
                    this.registerViewItemEvent();
                    this.productsSliderKey += 1;
                }, (response) => {
	                this.working = false
	                if(response.body.data) {
		                window.location.href = this.redirectToSearch(response.body.data[0].data.token)
	                }
                })
            } else {
                this.$http.post(`/parkinglot/${apr.content.parkinglot.id}/search`, {
                    checkindate: this.checkin_value,
                    checkoutdate: this.checkout_value,
                }).then((response) => {
                    this.alternatives = response.body.alternatives
                    this.options = response.body.parkinglot.options
                    this.parkinglot = response.body.parkinglot
                    this.airportCode = response.body.search.data.airport.data.code
                    this.working = false
                    this.search = response.body.search.data
                    this.setSelectPlaceholders()
	                this.registerPriceComparisonEvent();
                    this.registerViewItemEvent();
                    this.productsSliderKey += 1;

                }, (response) => {
	                this.working = false
                	if(response.body.data) {
		                window.location.href = this.redirectToSearch(response.body.data[0].data.token)
	                }
                })
            }
        },

        updateTimes() {
            this.errorTimes = false
            this.errorNoTimes = false
            this.warningShuttleTimes = false
            this.resetValidationErrors()
            this.working = true;

            if (this.isUpdateTimesInProgress) {
	            this.working = false
                return
            }

            if (!this.isSelectedTimesValid()) {
	            this.working = false
                return false;
            }

            this.isUpdateTimesInProgress = true
            this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${this.search.token}?filter_by_parkinglot_id=true`, {
                checkintime: this.search.checkintime.default,
                checkouttime: this.search.checkouttime.default,
                checkindate: this.search.checkindate.date_format_1,
                checkoutdate: this.search.checkoutdate.date_format_1,
            }).then((response) => {
                this.errorNoTimes = null
                this.isUpdateTimesInProgress = false

                this.products = response.body.parkinglot.products.data
                this.search = response.body.search.data

                this.step++
                this.working = false
	            this.registerPriceComparisonEvent();

                let checkinDate = moment(this.search.checkindate.date_format_2, 'MMM DD, YYYY');
                let checkoutDate = moment(this.search.checkoutdate.date_format_2, 'MMM DD, YYYY');
                let stayDuration = checkoutDate.diff(checkinDate, 'days') + 1;
                let daysAheadOfBooking = checkinDate.diff(moment(), 'days') + 1;

                dataLayer.push({
                    event: 'parkingTimes',
                    parkingDepartureTime: this.search.checkintime.standard_time,
                    parkingReturnTime: this.search.checkouttime.standard_time,
                    stayDuration: stayDuration,
                    daysAheadOfBooking: daysAheadOfBooking,
                });

                if (response.body.warning) {
                    this.warningShuttleTimes = true;
                    this.warningShuttleTimesMessage = response.body.warning.times.message;
                    this.errorCheckInTime = response.body.warning.times.checkin;
                    this.errorCheckOutTime = response.body.warning.times.checkout;
                }
            }, (response) => {
                this.working = false
                this.isUpdateTimesInProgress = false

                if (response.body.checkintime && response.body.checkouttime) {
                    this.errorNoTimes = 'both'
                    return
                } else if (response.body.checkintime) {
                    this.errorNoTimes = 'check-in'
                    return
                } else if (response.body.checkouttime) {
                    this.errorNoTimes = 'check-out'
                    return
                }

                this.errorNoTimes = null

                if (response.body.data) {
                    let errors = _.get(response.body.data, '0.errors');
                    if (errors) {
                        for (var errorName in errors) {
                            let $field = $("#Select_Parking").find(`[name="${errorName}"]`);

                            if ($field.length) {
                                $field.parsley().addError('custom-error', {message: errors[errorName]});
                            }
                        }
                        return;
                    }

                    if (_.get(response.body.data[0], 'data.unavailable_for_selected_times') === true) {
                        this.errorTimes = true;
                        this.errorTimesMessage = _.get(response.body.data[0], 'data.message');

                        dataLayer.push({
                            'event': 'GaEvent',
                            'EventCategory': 'Parkinglot',
                            'EventAction': 'Modal - Times Unavailable'
                        })
                    } else {
                        window.location.href = this.redirectToSearch(response.body.data[0].data.token)
                    }
                }
            })

            //dataLayer - checkout step1 - Parking Times
            let providerName = _.get(self.apr.content.parkinglot, 'provider');
            let category = this.getLotCategory(providerName);
            let $discountPriceBlock = $("span.strikeout").parent().find("span:nth-child(2)");
            let price = $discountPriceBlock.length === 1
                ? parseFloat($discountPriceBlock.html().replace("$", ""))
                : self.apr.content.parkinglot.rate;
            dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 1, 'option': 'Parking Times'},
                        'products': [{
                            'name': `${this.content.airport.code} ${self.apr.content.parkinglot.name}`,
                            'id': `${this.content.airport.code}`,
                            'price': price,
                            'brand': '',
                            'category': category,
                            'variant': '',
                            'quantity': 1
                        }]
                    }
                }
            })
            dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Modal - Times Entered'})
        },

        resetValidationErrors() {
            let $errors = $(".parsley-error");
            if ($errors.length) {
                $errors.parsley().reset();
            }
        },

        setSelectPlaceholders() {
            // for vue - to select default values
            if (this.search.checkintime.default === null || this.search.checkintime.default === undefined) {
                this.search.checkintime.default = ''
            }
            if (this.search.checkouttime.default === null || this.search.checkouttime.default === undefined) {
                this.search.checkouttime.default = ''
            }
        },

        isSelectedTimesValid() {
            if (this.search.checkintime.default == '' || this.search.checkintime.default === undefined) {
                this.errorNoTimes = 'check-in'
            }
            if (this.search.checkouttime.default == '' || this.search.checkouttime.default === undefined) {
                this.errorNoTimes = 'check-out'
            }
            if ((this.search.checkintime.default == '' || this.search.checkintime.default === undefined) &&
                (this.search.checkouttime.default == '' || this.search.checkouttime.default === undefined)) {
                this.errorNoTimes = 'both'
            }

            if (this.errorNoTimes) {
                return false;
            }
            return true;
        },

        showTimesModal() {
            if (!this.checkin_value || !this.checkout_value) {
                return this.focusDate()
            }

			$('#Edit_Times, #Select_Parking').modal('show')
        },

        goToCarouselSlide(index){
            $('.carousel').carousel(index)
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Photos', 'EventLabel': 'Next'})
        },

        selectProduct(productId) {
            this.product = productId
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Modal - Parking Type Selected'})

            this.goToCheckout()
        },

        redirectToSearch(token) {
            return `/search/soldout?token=${token}&airport=${this.content.airport.code}&parkinglot=${this.parkinglot.name}`
        },

        goToCheckout() {
            this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${this.search.token}/product`, {
                product: this.product
            }).then((response) => {
                let providerName = _.get(self.apr.content.parkinglot, 'provider');
                let category = this.getLotCategory(providerName);
                let price = this.selectedProduct.rate
                let productName = this.name;
                let productId = this.product

                dataLayer.push({
                    'event': 'add_to_cart',
                    'ecommerce': {
                        'currency': '',
                        'value':  price,
                        'items': [
                            {
                                'item_id': productId,
                                'item_name': `${this.airportCode} ${productName}`,
                                'coupon': '',
                                'currency': '',
                                'discount': 0,
                                'item_list_position': 0,
                                'item_category': category,
                                'item_list_name': '',
                                'price': price,
                                'quantity': 1,
                                'upgraded': false
                            },
                        ],
                    }
                });

                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': 2, 'option': 'Parking Type'},
                            'products': [{
                                'name': `${this.content.airport.code} ${self.apr.content.parkinglot.name}`,
                                'id': `${this.content.airport.code}`,
                                'price': price,
                                'brand': '',
                                'category': category,
                                'variant': '',
                                'quantity': 1
                            }]
                        }
                    }
                })

                window.location.href = this.checkoutLocation(response)
            }, (response) => {
                $('button').prop('disabled', false)
                alert('Sorry we are unable to process you request.')
            })

        },

	    searchParking(url = null) {
		    if (!this.checkin_value || !this.checkout_value) {
			    return this.focusDate()
		    }

		    this.working = true

		    let searchURL = `${apr.app_url}/search`;
		    let searchParams = {
			    airport: apr.content.airport.code,
			    package: 'APR',
			    date1: this.checkin_value,
			    date2: this.checkout_value,
			    guests: 2,
			    rooms: 1,
			    hotel_id: 0,
			    checkindate: this.checkin_value,
			    checkoutdate: this.checkout_value,
		    };

		    this.$http.post(searchURL, searchParams).then((response) => {
			    if (response.data.redirect) {
				    window.location.href = response.data.redirect
			    }else if(response.data.data.redirect_url){
				    window.location.href = response.data.data.redirect_url
			    }
			    setTimeout(() => {
				    self.inProgressSearch = false;
			    }, 7500)
		    })
	    },

        appendPaginationLinks() {
            $('link[rel=next], link[rel=prev]').remove();

            if (this.content.reviews.next_page_url) {
                $('head').append(`<link rel="next" href="${this.content.reviews.next_page_url}" />`)
            }

            if (this.content.reviews.prev_page_url) {
                $('head').append(`<link rel="prev" href="${this.content.reviews.prev_page_url}" />`)
            }
        },

        paginationLink(link, push) {
            if (link) {
                this.reviewLoading = true
                this.$http.get(link).then((response) => {
                    this.reviewLoading = false
                    this.content = response.data
                    this.appendPaginationLinks()

                    if (push) {
                        $('#reviews-modal .modal-body').animate({scrollTop: 160}, 1000)
                    }

                }, (response) => {
                    this.reviewLoading = false
                    alert('Sorry we could not get that page for you')
                })
            }
        },

        focusDate() {
            setTimeout(() => {
	            this.$refs.datepicker.openModal('date1')
            }, 100)
        },

        setDates(start, end){
            this.checkin_value = start
            this.checkout_value = end
        },

        checkoutLocation(response) {
            let payment = this.parkinglot.prepaid ? 'prepaid' : 'deposit'

            return `${apr.app_url}/checkout/${payment}?token=${this.search.token}`;
        },

        editTimes() {
            this.step = 1;
            this.warningShuttleTimes = false
            this.triggerPageEvent('Modal - Edit Times');
        },

        scrollTo(id) {
            $("html,body").animate({scrollTop: $(`#${id}`).offset().top - 48}, "slow");
        },

        triggerTabEvent(eventName) {
            this.scrollTo(eventName.toLowerCase())

            this.triggerPageEvent('Tabs', eventName)
        },

        triggerPageEvent(action, label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Parkinglot',
                'EventAction': action,
                'EventLabel': label
            })
        },

        registerEvent(name) {
            dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': name})
        },

        alternativeSelected(name) {
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Alternative parking lot selected', 'EventLabel': name})
        },

        registerPriceComparisonEvent() {
            let includes = ["YUL", "CLE", "BOS", "TPA", "YYZ", "LAX", "SEA", "DTW", "ORD", "MSP"];
            if (
                includes.includes(this.content.airport.code)
                && this.search.days
                && _.get(this.parkinglot, 'savings.savings_total_str')
            ) {
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': 'Price comparison',
                    'EventAction': this.search.days + ' Days - ' + this.parkinglot.savings.savings_total_str,
                    'EventLabel': window.location.href
                })
            }
        },

        isOneDateSearch() {
            return this.search.checkindate.date_format_1 === this.search.checkoutdate.date_format_1;
        },

        registerViewItemEvent() {
            let providerName = _.get(self.apr.content.parkinglot, 'provider');
            let category = this.getLotCategory(providerName);

            if (!this.viewItemRegistered && this.checkin_value.length !== 0 && this.checkout_value.length !== 0) {
                let priceWithDiscount = this.parkinglot.rate;

                dataLayer.push({
                    'event': 'view_item',
                    'ecommerce': {
                        'currency': '',
                        'value':  priceWithDiscount,
                        'items': [
                            {
                                'item_id': this.parkinglot.id,
                                'item_name': `${this.airportCode} ${this.parkinglot.name}`,
                                'coupon': '',
                                'currency': '',
                                'discount': 0,
                                'item_list_position': 0,
                                'item_category': category,
                                'item_list_name': '',
                                'price': priceWithDiscount,
                                'salesTaxIncluded': this.parkinglot.taxes_included,
                                'feesIncluded': false,
                                'fees': 0.00,
                                'tax' : 0.00,
                                'quantity': 1,
                            },
                        ],
                    }
                });

                this.viewItemRegistered = true;
            }
        },
    }
});
