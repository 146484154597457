import Heading from "../../components/heading";
import LightBox from 'vue-image-lightbox'
import ProductImagesSlider from "../../components/ProductImagesSlider.vue";
import ExpandableContent from "../../components/ExpandableContent.vue";
import Modal from '../../components/BsModal.vue'
import BaseSlider from '../../components/NewTemplate/BaseSlider.vue';
import AccordionInfo from "../../../../shared/js/components/AccordionInfo.vue";

Vue.component('page-hotel', {
    components: {
        AccordionInfo,
        LightBox,
        ProductImagesSlider,
        Modal,
        ExpandableContent,
        BaseSlider
    },
    mixins: [ Heading ],

    data: function () {
        return {
            showSearchbox: false,
            showAllPhotos: false,
            images: [],
            carousel_slide: 1,

            search: {
                checkindate: {},
                checkoutdate: {},
                checkintime: {
                    default: ''
                },
                checkouttime: {
                    default: ''
                }
            },

            isCruiseport: (_.get(window, 'apr.search_psf.airport.type') === 'CruisePort'),
            token: _.get(window, 'apr.token'),
            hotel: apr.hotel,
            airport: _.get(window, 'apr.hotel.airport'),

            reviewLoading: false,
            products: {},
            showOffCanvas: false,

            searchbox: null,
            search_packages: null,
            descriptions: null,

            inProgressSearch: false,

            available: true,
            errorMessage: '',
            unavailabilityType: null,
            productsSliderKey: 0,
        }
    },

    mounted() {
        let self = this
        let psf_search_results = _.get(window, 'apr.psf_search_results');

        // set from session (from PSF hotel search response)
        this.searchbox = _.get(psf_search_results, 'search');
        this.search_packages = _.get(psf_search_results, 'rates');

        this.available = _.get(psf_search_results, 'available', true);
        this.unavailabilityType = _.get(psf_search_results, 'unavailability_type', null);
        this.errorMessage = _.get(psf_search_results, 'error', null);

        this.hotel.overview.prices.avg = _.get(psf_search_results, 'best_rate');
        this.descriptions = _.get(psf_search_results, 'descriptions');

        dataLayer.push({
            'event' : 'airportCodeDetails',
            'airportCode': `${this.airport.code}`,
        });

        //dataLayer - productDetailImpressions
        this.$nextTick(function () {
            let category = this.hotel.provider === 'PSF'
                ? "Hotel & Parking Package"
                : "";
            let variant = typeof(this.available) != 'undefined' && this.available === true
                ? "available"
                : "sold out";
            let price = typeof(this.hotel.psf_search_results.best_rate) != 'undefined'
                ? this.hotel.psf_search_results.best_rate
                : typeof(this.hotel.psf_search_results.rates[0].package_rate != 'undefined')
                    ? this.hotel.psf_search_results.rates[0].package_rate
                    : "";
            dataLayer.push({
                'event': 'productDetailImpressions',
                'ecommerce': {
                    'detail': {
                        'actionField': {'list': 'Apparel Gallery'},
                        'products': [{
                            'name': `PSF ${this.airport.code} ${this.hotel.name}`,
                            'id': `PSF ${this.airport.code}`,
                            'price': price,
                            'brand': '',
                            'category': category,
                            'variant': variant
                        }]
                    }
                }
            })
        });

        // callbacks
        this.$root.$on('hotel-package-search', function (successData) {
            $('#searchbox-hotel-modal').modal('hide');
            let successResponse = _.get(successData, 'body.data');

            self.updatePropertiesAfterSearch(successResponse);
        });

        this.$root.$on('hotel-package-search-failed', function (errorData) {
            $('#searchbox-hotel-modal').modal('hide');
            let errorResponse = _.get(errorData, 'body.data');

            self.updatePropertiesAfterSearch(errorResponse);
        });
        
        this.initImages();
    
        $('#carousel-example-generic').on('slid.bs.carousel', function (slide) {
            self.carousel_slide = slide.to + 1;
        })

        // navigation bar
        $('body').scrollspy({target: '#navbar-sticky', offset:100});
        // this.scrollStick();
    },

    computed: {
        searchLink() {
            if (this.token && this.searchbox.id && this.searchbox.package !== 'APR' && this.searchbox.package !== 'HRO') {
                let airportCode = this.airport.code.toLowerCase()
                let queryData = {
                    checkindate: _.get(this.searchbox, 'date1.date_format_3', null),
                    checkoutdate: _.get(this.searchbox, 'date2.date_format_3', null),
                    token: this.token,
                }
                let qs = this.encodeQueryData(queryData)

                return `/psf_search/${airportCode}?` + qs;
            }

            return false;
        },

        packageTypeForErrorMessage() {
            return this.getAlternativePackageText(_.get(this.$store.getters, 'session.package'));
        }
    },

    watch: {},

    methods: {
        updatePropertiesAfterSearch(response) {
            if (_.get(response, 'search')) {
                this.searchbox = _.get(response, 'search');
            }
            // apr search token
            if (_.get(response, 'apr_search')) {
                this.token = _.get(response, 'apr_search.token');
            }

            this.available = _.get(response, 'available', false);
            this.unavailabilityType = _.get(response, 'unavailability_type', null);
            this.errorMessage = _.get(response, 'error', null);

            this.hotel.overview.prices.avg = _.get(response, 'best_rate');
            this.search_packages = _.get(response, 'rates', null);

            if (_.get(response, 'alternatives')) {
                this.hotel.alternatives = _.get(response, 'alternatives');
                this.productsSliderKey += 1;
            }
            if (_.get(response, 'descriptions')) {
                this.descriptions = _.get(response, 'descriptions');
            }

            if (_.get(response, 'important_information')) {
                this.hotel.info.important_information = _.get(response, 'important_information');
                this.hotel.info.checkin_instructions = _.get(response, 'checkin_instructions');
            }
        },
        
        initImages(){
            let self = this
            let thumb_url = this.hotel.images.medium_url + '/'
            let large_url = this.hotel.images.large_url + '/'
            let photos = this.hotel.images.data
        
            photos.forEach((photo) => {
                self.images.push({
                    thumb: thumb_url + photo,
                    src: large_url + photo,
                })
            })
        },
    
        carouselSlide(dir){
            if(dir === "right"){
                $('.carousel').carousel('prev')
            }else{
                $('.carousel').carousel('next')
            }
        },
    
        lightboxSlide(dir){
            if(dir === "right"){
                this.$refs.lightbox.nextImage()
            }else if(dir === "left"){
                this.$refs.lightbox.previousImage()
            }else if(dir === "up" || dir === "down"){
                this.$refs.lightbox.closeLightBox()
            }
        },
    
        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        },

        toggleSearchBox() {
            this.showSearchbox = !this.showSearchbox;
            $('#searchbox-hotel-modal').modal('show');
        },

        showTravelDates() {
            this.showSearchbox = true;
            $('#searchbox-hotel-modal').modal('show');
        },

        showRoomModal() {
            $('#Select_Rooms').modal('show')
        },

        goToCarouselSlide(index) {
            $('.carousel').carousel(index)
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Parkinglot',
                'EventAction': 'Photos',
                'EventLabel': 'Next'
            })
        },

        redirectToSearch(token) {
            return `/search/soldout?token=${token}&airport=${this.content.airport.code}&parkinglot=${this.parkinglot.name}`
        },

        formatDate(val) {
            return moment(val).format('MMM D, YYYY')
        },

        goToCheckout(roomId, rateId, searchId, provider = 'psf', index = null) {
            let $button = index !== null ? $("#booknow-" + index) : null;
            $button.html(`<i class="fas fa-spinner fa-spin" aria-hidden="true"></i>`);
            $('button').prop('disabled', true);

            this.$http.post(_.get(window, 'apr.checkout_request_token_url'), {
                room_id: roomId,
                rate_id: rateId,
                search_id: searchId,
                provider: provider,
                hotel_id: this.hotel.hotel_id,
                token: this.token,

            }).then((response) => {
                let token = _.get(response, 'body.data.token');
                if (token) {
                    //dataLayer - addToCart
                    let category = this.hotel.provider === 'PSF'
                        ? "Hotel & Parking Package"
                        : "";
                    let variant = typeof(this.available) != 'undefined' && this.available === true
                        ? "available"
                        : "sold out";
                    let $priceHtmlTag = $button.parent().children(".price").length === 1
                        ? $button.parent().children(".price")
                        : $button.parent().parent().children(".room-price")
                    let price = parseFloat($priceHtmlTag.text().trim().replace("$", ""));

                    dataLayer.push({
                        'event': 'addToCart',
                        'ecommerce': {
                            'currencyCode': 'USD',
                            'add': {
                                'products': [{
                                    'name': `PSF ${this.airport.code} ${this.hotel.name}`,
                                    'id': `PSF ${this.airport.code}`,
                                    'price': price,
                                    'brand': '',
                                    'category': category,
                                    'variant': variant,
                                    'quantity': 1
                                }]
                            }
                        }
                    })

                    window.location.href = `${_.get(window, 'apr.checkout_page_url')}?token=${token}`;
                }

                this.triggerPageEvent('Buttons', 'Select Room - Proceeded To Checkout');

            }, (response) => {

                alert('Sorry we are unable to process you request.')
                $button.html('Book');
                $('button').prop('disabled', false);
            })
        },

        initPagination() {
            let page = this.getQueryVariable('reviews')

            if (page) {
                this.paginationLink(window.location.href, false)
            } else {
                this.appendPaginationLinks()
            }
        },

        appendPaginationLinks() {
            $('link[rel=next], link[rel=prev]').remove();

            if (this.content.reviews.next_page_url) {
                $('head').append(`<link rel="next" href="${this.content.reviews.next_page_url}" />`)
            }

            if (this.content.reviews.prev_page_url) {
                $('head').append(`<link rel="prev" href="${this.content.reviews.prev_page_url}" />`)
            }
        },

        paginationLink(link, push) {
            if (link) {
                this.reviewLoading = true
                this.$http.get(link).then((response) => {
                    this.reviewLoading = false
                    this.content = response.data
                    this.appendPaginationLinks()

                    if (push) {
                        $('#reviews-modal .modal-body').animate({scrollTop: 160}, 1000)
                    }

                }, (response) => {
                    this.reviewLoading = false
                    alert('Sorry we could not get that page for you')
                })
            }
        },

        setDates(start, end) {
            this.checkin_value = start
            this.checkout_value = end
        },

        scrollTo(id) {
            $("html,body").animate({scrollTop: $(`#${id}`).offset().top - 48}, "slow");
        },

        triggerTabEvent(eventName) {
            this.scrollTo(eventName.toLowerCase())

            this.triggerPageEvent('Tabs', eventName)
        },

        triggerPageEvent(action, label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'PSF Hotel',
                'EventAction': action,
                'EventLabel': label
            })
        },

        getNightsNumber(packageName) {
            return (packageName === 'PSFS' || packageName === 'PSCS') ? 2 : 1
        },

        getAlternativePackageText(pckg) {
            if (pckg === 'HRO') {
                return 'Hotel Room Only'
            } else if (pckg === 'PSF' || pckg === 'PSC') {
                return 'Sleep at Beginning of Trip'
            } else if (pckg === 'PFS' || pckg === 'PCS') {
                return 'Sleep at End of Trip'
            } else if (pckg === 'PSFS' || pckg === 'PSCS') {
                return 'Sleep at Beginning & End of Trip'
            }
        },

        encodeQueryData(data) {
            let ret = [];
            for (let d in data) {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
            return ret.join('&');
        },

        scrollStick() {
            $(window).scroll(function () {
                var height = $(window).scrollTop();
                var navbarComp = $('.navbar-container').offset();
                if (height > navbarComp.top - 50) {
                    $('#navbar-sticky').addClass('sticky')
                } else {
                    $('#navbar-sticky').removeClass('sticky')
                }
            });
        },
    }
});
