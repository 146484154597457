
Vue.component('page-shuttle-landing', {

    data: function () {
        return {
            airport: apr.content ? apr.content.content : ''
        }
    },

    methods: {
        ecommercePsfTracking(hotelName, index) {
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': 'PSF Search Results'},
                        'products': [{
                            'name': `PSF ${this.airport.code} ${hotelName}`,
                            'id': this.airport.code,
                            'price': '',
                            'brand': '',
                            'category': '',
                            'variant': 'available',
                            'position': index
                        }]
                    }
                }
            })
        },
        changePackage(name){
            eventHub.$emit('package_type', name);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
		},
    }
});
