<template>
    <div class="container">
        <slick-carousel ref="slick" class="base-slider" :options="slickOptions" @afterChange="handleAfterChange" :key="carouselKey">
            <slot />
        </slick-carousel>
    </div>
</template>

<script>
import Slick from 'vue-slick'

export default {
    components: {
        'slick-carousel': Slick,
    },

    props: {
        carouselKey: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            slickOptions: {
                autoplay: false,
                autoplaySpeed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: true,
                dots: true,
            },
        }
    },

    methods: {
        handleAfterChange(event, slick, currentSlide) {
            const prev = this.$refs.slick.$el.querySelector('.slick-prev')
            const next = this.$refs.slick.$el.querySelector('.slick-next')
            if (prev) {
                prev.style.opacity = currentSlide === 0 ? '0' : '1'
                prev.style.pointerEvents = currentSlide === 0 ? 'none' : 'all'
            }
            if (next) {
                next.style.opacity = currentSlide === slick.$slides.length - 1 ? '0' : '1'
                next.style.pointerEvents = currentSlide === slick.$slides.length - 1 ? 'none' : 'all'
            }
        },
    },
}
</script>

<style lang="scss">
.base-slider {
    margin-left: -25px;
    margin-right: -25px;
    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    .slick-track {
        display: flex;
        align-items: stretch;
    }
    .slick-slide {
        display: flex;
        align-items: stretch;
        height: auto;
        & > div {
            width: 100%;
            display: flex;
            padding: 10px;
            align-items: stretch;
            height: 100%;
        }
    }

    .slick-prev {
        opacity: 0;
        pointer-events: none;
    }

    .slick-prev {
        left: -3px;
    }
    .slick-next {
        right: -3px;
    }

    .slick-prev,
    .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        width: 32px;
        height: 32px;
        border-radius: 50px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
        z-index: 10;
        transition: opacity 0.3s;
        &:hover {
            background: #fff;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        content: '';
        display: block;
        height: 16px;
        width: 13px;
        opacity: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .slick-prev {
        left: -5px;
        &:before {
            margin-right: 2px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999999 10L4.48046 6.66667L6.93031 4.32039L11.4414 4.85949e-07L14.9219 3.33333L10.4277 7.63754L17 7.63754L17 12.3463L10.4277 12.3463L14.9219 16.6667L11.4414 20L6.93031 15.6796L4.48047 13.3333L0.999999 10Z' fill='%23B4E076'/%3E%3C/svg%3E%0A");
        }
    }
    .slick-next {
        right: -5px;
        &:before {
            margin-left: 1px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 10L14.5195 13.3333L12.0697 15.6796L7.55861 20L4.07814 16.6667L8.57233 12.3625H2L2 7.65372L8.57233 7.65372L4.07814 3.33333L7.55861 0L12.0697 4.32039L14.5195 6.66667L18 10Z' fill='%23B4E076'/%3E%3C/svg%3E%0A");
        }
    }

    .slick-dots {
        position: relative;
        bottom: auto;
        display: flex;
        justify-content: center;
        margin-top: 14px;
        li {
            width: 8px;
            height: 8px;
            button {
                padding: 0;
                background: #b4e076;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                opacity: 0.4;
                &::before {
                    display: none;
                }
            }
        }
    }
    .slick-dots li.slick-active button {
        opacity: 1;
        transform: scale(1.25);
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}

.spirit-slider {
  .base-slider {
    margin-left: -25px;
    margin-right: -25px;
    &.slick-dotted.slick-slider {
      margin-bottom: 0;
    }
    .slick-track {
      display: flex;
      align-items: stretch;
    }
    .slick-slide {
      display: flex;
      align-items: stretch;
      height: auto;
      & > div {
        width: 100%;
        display: flex;
        padding: 10px;
        align-items: stretch;
        height: 100%;
      }
    }
    .slick-prev {
      opacity: 0;
      pointer-events: none;
    }
    .slick-prev {
      left: -3px;
    }
    .slick-next {
      right: -3px;
    }
    .slick-prev,
    .slick-next {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 110px;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
      z-index: 10;
      transition: opacity 0.3s;
      &:hover {
        background: #fff;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      content: '';
      display: block;
      width: 26px;
      height: 26px;
      opacity: 1;
    }
    .slick-prev:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 44 44' fill='none'%3E%3Cpath d='M18.4474 31.0022L18.4474 23.713L34.7022 23.713L34.7569 20.0502L18.4474 20.0502L18.4474 12.7793L9.33594 21.8907L18.4474 31.0022Z' fill='%230073e6'/%3E%3C/svg%3E");
    }
    .slick-next:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 44 44' fill='none'%3E%3Cpath d='M25.5526 12.9959L25.5526 20.285L9.2978 20.285L9.24313 23.9478L25.5526 23.9478L25.5526 31.2188L34.6641 22.1073L25.5526 12.9959Z' fill='%230073e6'/%3E%3C/svg%3E");
    }
    .slick-dots li {
      margin: 0 4px;
    }
    .slick-dots li,
    .slick-dots li button {
      height: 8px;
      width: 8px;
    }
    .slick-dots li button {
      padding: 0;
    }
    .slick-dots li button:before {
      content: '';
      position: static;
      display: block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: #D1D5DA;
    }
    .slick-dots li.slick-active button:before {
      background: rgb(0, 115, 230);
    }
    .slick-dots {
      line-height: 0.5rem;
      position: relative;
      bottom: auto;
      padding-top: 14px;
    }
  }
}
</style>
