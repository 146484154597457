<template>
    <section v-if="questions && questions.length" class="faq-accordion">
        <div class="container">
            <h2 class="faq-accordion__heading section-title">{{ title }}</h2>
            <div class="section-subtitle" v-html="subtitle"></div>
            <div class="accordion">
                <template v-for="(faq, index) in questions">
                    <div class="accordion-item" :class="{ active: showQuestions.indexOf(index) >= 0 }">
                        <div class="accordion-item--heading" @click="toggleItem(index)">
                            {{ faq.title }}
                        </div>
                        <p class="accordion-item--body" v-html="faq.content"></p>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Frequently Asked Questions',
        },
        subtitle: {
            type: String,
            default: 'Find answers to most asked questions.',
        },
        questions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showQuestions: [],
            multiSelect: false,
        };
    },
    methods: {
        toggleItem(index) {
            let faqIndex = this.showQuestions.indexOf(index);
            if (this.multiSelect) {
                if (faqIndex >= 0) {
                    this.showQuestions.splice(faqIndex, 1);
                } else {
                    this.showQuestions.push(index);
                }
            } else {
                if (this.showQuestions[0] === index) {
                    this.showQuestions = [];
                } else {
                    this.showQuestions = [];
                    this.showQuestions.push(index);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.faq-accordion {
    .accordion {
        margin-top: 2rem;
        border-top: 1px solid #e3e4e4;
        border-bottom: 1px solid #e3e4e4;
        &-item {
            padding: 24px 0;
            &:not(:first-child) {
                border-top: 1px solid #e3e4e4;
            }
            &--heading {
                position: relative;
                font-weight: bold;
                color: #212529;
                user-select: none;
                cursor: pointer;
                padding-right: 36px;
                font-size: 1rem;
                font-size: 1.563rem;
                &::after {
                    content: '+';
                    position: absolute;
                    right: 0;
                    top: 0.875rem;
                    font-size: 1.5rem;
                    line-height: 0;
                }
            }
            &--body {
                display: none;
                padding-top: 1rem;
                font-size: 1rem;
                font-size: 1.563rem;
                color: #4c5054;
                font-weight: 400;
            }

            &.active {
                padding-bottom: 0;
            }

            &.active .accordion-item--heading {
                color: #25a0de;
                &::after {
                    content: '_';
                    top: 0.175rem;
                }
            }
            &.active .accordion-item--body {
                display: block;
            }
        }
    }
}
</style>
