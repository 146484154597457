<template>
    <section v-if="showTabsComponent" class="guide-section">
        <div class="guide-section__container container">
            <h2 v-if="info.title" class="guide-section__title section-title">{{ info.title }}</h2>
            <div v-if="info.subtitle" class="guide-section__subtitle section-subtitle" v-html="info.subtitle"></div>
            <div class="guide-section__tabs">
                <div class="tabs">
                    <div class="tabs__controls" :class="{ 'is-centered': tabsControlsCentered }">
                        <button
                            class="tabs__controls-arrow tabs__controls-arrow--left"
                            :class="{ 'is-active': !isAtLeftMargin }"
                            @click="scrollTab(-300)"
                        >
                            <i class="fa fa-chevron-left"></i>
                        </button>
                        <ul class="tabs-controls" ref="tabs-controls" @scroll="showArrows">
                            <li v-for="(item, index) in tabs" :key="item.title" class="tabs-controls__control">
                                <button
                                    class="tabs-controls__btn"
                                    :class="{ 'is-active': activeTab === index }"
                                    @click="setActiveTab(index)"
                                >
                                    {{ item.title }}
                                </button>
                            </li>
                        </ul>
                        <button
                            class="tabs__controls-arrow tabs__controls-arrow--right"
                            :class="{ 'is-active': !tabsControlsCentered && !isAtRightMargin }"
                            @click="scrollTab(300)"
                        >
                            <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>
                    <div class="tabs__content-wrapper" ref="tabs-content">
                        <div
                            v-for="(item, index) in tabsContent"
                            :key="index"
                            class="tabs__content"
                            :class="{
                                'is-active': activeTab === index,
                                'is-tab-content-open': showFullContent,
                                'is-with-toggle': item.showToggleBtn,
                            }"
                        >
                            <div class="tabs__content-text" v-html="item['originalContent']"></div>
                            <div class="tabs__content-btn" v-if="item.showToggleBtn">
                                <button class="show-more-btn" @click="toggleVisibility">
                                    <span class="show-more-btn__up"><i class="fa fa-chevron-up"></i></span>
                                    <span class="show-more-btn__text">{{ btnText }}</span>
                                    <span class="show-more-btn__down"><i class="fa fa-chevron-down"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        info_type: {
            type: String,
            default: 'airport_guide',
        },
    },

    data() {
        return {
            info: apr[this.info_type],
            showTabsComponent: false,
            activeTab: 0,
            showFullContent: false,
            tabsControlsCentered: false,
            tabsControlsContainerWidth: 0,
            isAtRightMargin: false,
            isAtLeftMargin: true,
        };
    },

    computed: {
        tabs() {
            if (!this.info || !this.info.tabs) return [];
            return this.info.tabs.filter((tab) => tab.title && tab.content);
        },
        tabsContent() {
            return this.tabs.map((tab) => {
                const textLength = tab.content.replace(/<[^>]*>/g, '').length;
                return {
                    originalContent: tab.content,
                    showToggleBtn: textLength > 340,
                };
            });
        },
        btnText() {
            return this.showFullContent ? 'Read less' : 'Read more';
        },
    },

    mounted() {
        if (this.info && this.info.tabs && this.info.title) this.showTabsComponent = true;

        this.$nextTick(() => {
            const tabsControls = this.$refs['tabs-controls'];
            if (!tabsControls) return;

            const tabsControlsItems = [...tabsControls.children];

            // Items width + parent gap
            const tabsControlsItemWidth =
                tabsControlsItems.reduce((width, el) => width + el.getBoundingClientRect().width, 0) +
                (tabsControlsItems.length - 1) * 32;
            this.tabsControlsContainerWidth = tabsControls.getBoundingClientRect().width;
            this.tabsControlsCentered = tabsControlsItemWidth <= this.tabsControlsContainerWidth;
        });
    },

    methods: {
        setActiveTab(index) {
            this.activeTab = index;
            this.showFullContent = false;
        },
        toggleVisibility() {
            this.showFullContent = !this.showFullContent;
        },
        showArrows: _.throttle(function (e) {
            const container = e.target;
            const scrollPosition = container.scrollLeft;
            const scrollWidth = container.scrollWidth;
            this.isAtRightMargin = scrollPosition >= scrollWidth - this.tabsControlsContainerWidth;
            this.isAtLeftMargin = scrollPosition === 0;
        }, 100),
        scrollTab(pixels) {
            const el = this.$refs['tabs-controls'];
            if (el) {
                el.scrollTo({
                    left: pixels,
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style lang="scss">
.guide-section {
    color: #343a40;
    text-align: center;
}

.tabs {
    &__controls {
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        &-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(248, 249, 250, 0.88);
            opacity: 0;
            pointer-events: none;
            font-size: 1rem;
            border: none;
            z-index: 100;
            .is-centered & {
                display: none;
            }
            &.is-active {
                opacity: 1;
                pointer-events: all;
            }
            &--left {
                left: 0;
            }
            &--right {
                right: 0;
            }
        }
    }

    &__content {
        display: none;
        position: relative;
        &.is-active {
            display: block;
        }
        &.is-tab-content-open {
            .tabs__content-text {
                height: auto;
                overflow: initial;
            }
            &:before {
                display: none;
            }
        }
        &.is-with-toggle {
            &:before {
                content: '';
                position: absolute;
                left: -15px;
                right: -15px;
                bottom: 65px;
                height: 4.25rem;
                background: linear-gradient(180deg, rgba(239, 242, 243, 0) 46.35%, #eff2f3 100%);
            }
        }

        &-text {
            height: 200px;
            overflow: hidden;
            text-align: left;
            font-size: 1rem;
            line-height: 1.438rem;
            color: #212529;
            p + h2,
            p + h3,
            p + h4,
            p + h5,
            p + h6 {
                margin-top: 20px;
            }
            p:last-child {
                margin-bottom: 0;
            }
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.438rem;
                margin-bottom: 0;
            }
            a {
                color: #34b4f5;
            }
        }
        &-btn {
            text-align: center;
            position: relative;
            z-index: 10;
            margin-top: 24px;
            .is-tab-content-open & {
                margin-top: 0;
            }
        }
    }
}

.tabs-controls {
    position: relative;
    display: flex;
    gap: 32px;
    padding: 0 1rem;
    margin-bottom: 24px;
    list-style-type: none;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .is-centered & {
        justify-content: center;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &__btn {
        position: relative;
        background: none;
        color: #343a40;
        font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.8rem;
        padding: 10px 0;
        border: none;
        white-space: nowrap;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #000;
            height: 1px;
            transition: height 0.3s, background-color 0.3s;
        }
        &.is-active {
            font-weight: 700;
            &::after {
                background: #0094ff;
                height: 4px;
            }
        }
    }
}

.show-more-btn {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: #25a0de;
    font-size: 1rem;
    font-weight: 600;
    background: transparent;
    border: none;
    &__up {
        display: none;
    }
    .is-tab-content-open & {
        .show-more-btn__up {
            display: block;
        }
        .show-more-btn__down {
            display: none;
        }
    }
}
</style>
