// airport landing page
$(function() {
    // highlight clicked parkinglot
    $(document).on('click', '#landing a.card .click-area', function () {
        $('#landing a.card').removeClass('focused');
        $(this).parent().addClass('focused');
    });

    // show more parkinglots
    $(document).on('click', '#landing .otherlot-button button', function () {
        $('#other_lots').show();
        $('.otherlot-button').hide();
    });

    // read more/less text of details or reviews
    $(document).on('click', '#landing .readmore', function (event) {
        event.preventDefault();

        $(this).parent().hide();
        $(this).parent().next().show();
    });
    $(document).on('click', '#landing .readless', function (event) {
        event.preventDefault();

        $(this).parent().hide();
        $(this).parent().prev().show();
    });

    // copy coupon
    $(document).on('click', '#landing .coupon .coupon-copy-button', function () {
        const el = document.createElement('textarea');
        el.value = $("#couponCode").val();
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        const $button = $(this);
        $button.removeClass('coupon-copy-button').addClass('coupon-copied-button').html('COPIED!')
    });
});
