export default {
	methods: {
		getSearchParams() {
			return {
				airport: this.query.airport.code,
				package: this.query.package,
				date1: this.query.date1,
				date2: this.query.date2,
				guests: this.query.guests,
				rooms: this.query.rooms,
				hotel_id: apr.hotel ? apr.hotel.hotel_id : 0,
				checkindate: this.query.date1,
				checkoutdate: this.query.date2,
			};
		},

		getSearchParamsSF() {
			return {
				trip_type: this.shuttlefinder.trip_type,
				dropoff: { place_id: this.shuttlefinder.dropoff.place_id },
				dropoff_date: this.shuttlefinder.dropoff_date,
				pickup: { place_id: this.shuttlefinder.pickup.place_id },
				pickup_date: this.shuttlefinder.pickup_date,
				return_pickup_date: this.shuttlefinder.trip_type !== 'nonAirport' ? this.shuttlefinder.return_dropoff_date : this.shuttlefinder.return_pickup_date,
				return_dropoff_date: this.shuttlefinder.trip_type !== 'nonAirport' ? this.shuttlefinder.return_dropoff_date : this.shuttlefinder.return_pickup_date,
				passengers: this.shuttlefinder.passengers,
				return: this.shuttlefinder.return,
				referred_by: '10',
			}
		},

		getSearchUrl() {
			if (this.currentTab == 'parking') {
				return `${apr.app_url}/search`
			}
			if (this.currentTab == 'hotel') {
				return apr.psf_search_in_airport_url
			}
			if (this.currentTab == 'shuttle') {
				return apr.sf_search_in_airport_url
			}
		},

		getParams() {
			if (this.currentTab == 'parking') {
				return this.getSearchParams()
			}
			if (this.currentTab == 'hotel') {
				return this.getSearchParams()
			}
			if (this.currentTab == 'shuttle') {
				return this.getSearchParamsSF()
			}
		},

		initSearchboxForm() {
			let self = this
			let requesting = false;
			self.inProgressSearch = false;

			this.form.parsley().on('form:validate', function () {
				if (self.package != 'SF') {
					if (self.query.airport.code == '') {
						$('.airport').addClass('error')
					} else {
						$('.airport').removeClass('error')
					}
					if (self.query.date1 == '' || self.query.date2 == '' || self.query.date1 == undefined || self.query.date2 == undefined) {
						$('.no-dates').addClass('error')
					} else {
						$('.no-dates').removeClass('error')
					}
					if (self.query.date1 == '' || self.query.date1 == undefined) {
						$('.date1').addClass('error')
					} else {
						$('.date1').removeClass('error')
					}
					if (self.query.date2 == '' || self.query.date2 == undefined) {
						$('.date2').addClass('error')
					} else {
						$('.date2').removeClass('error')
					}
					if (self.query.package == null || self.selectedOption == 'null') {
						$('.sleep').addClass('error')
					} else {
						$('.sleep').removeClass('error')
					}
				} else {
					if (self.shuttlefinder.pickup.type == null) {
						$('.pickup').addClass('error')
					} else {
						$('.pickup').removeClass('error')
					}
					if (self.shuttlefinder.dropoff.type == null) {
						$('.dropoff').addClass('error')
					} else {
						$('.dropoff').removeClass('error')
					}
					if (self.shuttlefinder.dropoff_date == '') {
						$('.datetime').addClass('error')
					} else {
						$('.datetime').removeClass('error')
					}
					if (self.shuttlefinder.return_dropoff_date == '') {
						$('.returnDatetime').addClass('error')
					} else {
						$('.returnDatetime').removeClass('error')
					}
				}

			}).on("form:submit", function () {

				return false;

			}).on('form:success', function (formInstance) {
				self.form.parsley().reset();

				if (self.query.package == null) {
					console.error('Package is Null');
					return false;
				}

				if (self.inProgressSearch) {
					return false;
				}

				self.inProgressSearch = true;
				self.errors = null;

				if (self.query.package == 'PSF' && self.query.package == 'PFS' && self.query.package == 'PSFS') {
					self.triggerSearchboxEvent(apr.event_category, `Searched ${self.query.package}`, '')
				} else {
					self.triggerSearchboxEvent(apr.event_category, `Searched ${self.query.package}`, '')
				}

				if (self.type == 'searchbox-hotel') {

					self.$http.post(_.get(window, 'apr.psf_search_in_hotel_url'), self.getSearchParams()).then(response => {
						self.inProgressSearch = false;
						self.$parent.inProgressSearch = false;
						self.isEditMode = false;

						self.$root.$emit('hotel-package-search', response);

						let dataResponse = _.get(response, 'body.data');
						if (_.get(dataResponse, 'search')) {
							self.$store.dispatch('updateSession', _.get(dataResponse, 'search'));
						}


					}, error => {
						self.inProgressSearch = false;
						self.$parent.inProgressSearch = false;

						self.$root.$emit('hotel-package-search-failed', error);

					}, response => {
						let body = response.body
						if (self.hotel && self.token) {
							window.location.href = `/psf_search/${self.hotel.airport.code}?soldout=true&token=${self.token}&airport=&hotel=${self.hotel.name}`
						}
						self.errors = response.body.errors
						requesting = false;
						self.inProgressSearch = false
						$('#Edit_Times').modal('hide')
						self.isDefaultMode = false
						alert('Sorry but the hotel is not available for those dates.')
						self.$root.$emit('hotel-no-packages')
					});

				} else {

					self.$http.post(self.getSearchUrl(), self.getParams()).then(response => {
						if (response.data.redirect) {
							window.location.href = response.data.redirect
						} else if (response.data.data.redirect_url) {
							window.location.href = response.data.data.redirect_url
						}
						setTimeout(() => {
							self.inProgressSearch = false;
						}, 7500)
					}, response => {
						let errors = response.body

						if (_.get(response, 'body.errors')) {
							errors = _.get(response, 'body.errors')
						}
						self.errors = errors

						for (var errorName in errors) {
							let $field = self.form.find(`[name="${errorName}"]`);

							if ($field.length) {
								$field.parsley().addError('custom-error', {message: errors[errorName]});
							}
						}

						self.inProgressSearch = false;
					});

				}

				return false;
			});
		},
	}
}
