import FlightstatusAirline from '../../components/FlightStatus/FlightStatusSearchboxAirline'
import FSformater from '../../mixins/FSFormater'

Vue.component('page-flightstatus-airline', {

    components: { FlightstatusAirline },

    mixins: [ FSformater ],

    data() {
        return {
            results: { arriving: {}, departing: {} },
            type: apr.type,
            isLoading: false,
	        searchboxEdit: true,
	        information: apr.content,
	        hasSearched: false,
        }
    },
	
	watch: {
		'isLoading': function () {
			this.hasSearched = true;
		},
		'searchboxEdit': function () {}
	},

    mounted() {},

});