<template>
    <div :class="{ 'is-expanded': expanded }" class="expandable">
        <div
            class="expandable__content"
            ref="content"
            :style="{ maxHeight: expanded ? '3000px' : `${contentMaxHeight}px` }"
        >
            <slot></slot>
        </div>
        <div v-if="showButton" class="expandable__btn">
            <button class="apr-show-more-btn" @click="toggleVisibility" >
                <span>Show {{ expanded ? 'Less' : 'More' }}</span>
                <i class="fa fa-chevron-down"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        contentMaxHeight: {
            type: Number,
            default: 160,
        },
    },
    data() {
        return {
            expanded: true,
            showButton: false,
        }
    },
    mounted() {
        this.cleanEmptyParagraphs()
        this.checkHeight()
    },
    methods: {
        toggleVisibility() {
            this.expanded = !this.expanded
        },
        checkHeight() {
            this.$nextTick(() => {
                this.showButton = this.$refs.content.clientHeight > this.contentMaxHeight
                this.expanded = false
            })
        },
        cleanEmptyParagraphs() {
            const paragraphs = this.$refs.content.querySelectorAll('p')

            paragraphs.forEach(function (p) {
                // Check if the only child is a <br> element
                if (p.childNodes.length === 1 && p.childNodes[0].nodeName === 'BR') {
                    // Hide the paragraph if the only child is <br>
                    p.style.display = 'none'
                }
            })
        },
    },
}
</script>
