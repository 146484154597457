import {mapGetters} from 'vuex'
import Datepicker from "../datepicker"

export default {
	mixins: [Datepicker],
	props: {
		passedHOne: String,
		passedHTwo: String,
		passedHThree: String,
		passedOption: String,
		showSf: {
			type: Boolean,
			default: false
		},
		showHp: {
			type: Boolean,
			default: true
		},
	},

	data() {
		return {
			titles: apr.searchbox_titles,
			headings: apr.searchbox_heading,
			content: apr.content,

			isCruiseport: false,

			package: this.passedOption,
			h1: this.passedHOne,
			h2: this.passedHTwo,
			h3: this.passedHThree,
			
			token: null,
			form: null,
			errors: null,
			
			selectedOption: null,
			
			inProgressSearch: false,
		}
	},

	computed: {
		...mapGetters([
			'session', 'query', 'shuttlefinder', 'currentTab', 'sessionTab'
		]),
	},

	watch: {
		'selectedOption': function (val) {
			if (this.currentTab === 'hotel') {
				this.$store.dispatch('updatePackage', val)
			}
		},

		'query.airport.type': function (airportType) {
			// update searchbox on airport type change
			airportType = _.toLower(airportType);

			if (_.indexOf(['airport', 'cruiseport'], airportType) !== -1) { // airport & cruiseport
				if (airportType === 'cruiseport') {
					this.isCruiseport = true
				} else {
					this.isCruiseport = false
				}
			}
		},
	},
	
	mounted() {
		let self = this
		this.form = $(this.$refs.searchboxForm);
		
		// init from page data (airport page)
		if (_.get(window, 'apr.airport.code')) {
			let airport = {
				'type': _.toLower(_.get(window, 'apr.airport.type')),
				'code': _.get(window, 'apr.airport.code'),
				'name': _.get(window, 'apr.airport.name'),
				'city': _.get(window, 'apr.airport.address.city'),
				'state_code': _.get(window, 'apr.airport.address.state_code'),
				'latitude': _.get(window, 'apr.airport.geo.latitude'),
				'longitude': _.get(window, 'apr.airport.geo.longitude')
			};
			
			this.isCruiseport = (airport.type === 'cruiseport')
			this.$store.dispatch('updateAirport', airport)
		}else if(_.get(window, 'apr.content.airport_content.code')){
			let airport = {
				'type': _.toLower(_.get(window, 'apr.content.airport_content.type')),
				'code': _.get(window, 'apr.content.airport_content.code'),
				'name': _.get(window, 'apr.content.airport_content.name'),
				'city': _.get(window, 'apr.content.airport_content.city'),
				'state_code': _.get(window, 'apr.content.airport_content.address.state_code'),
				'latitude': _.get(window, 'apr.content.airport_content.geo.latitude'),
				'longitude': _.get(window, 'apr.content.airport_content.geo.longitude')
			};
			
			this.isCruiseport = (airport.type === 'cruiseport')
			this.$store.dispatch('updateAirport', airport)
		}

		this.$root.$on('session-received', function (data) {
			if (data.searchbox) {
				// use airport info from page (not from session)
				if (_.get(self.query, 'airport.code')) {
					data.searchbox.airport = _.get(self.query, 'airport');
				}

				// re-store session
				self.$store.dispatch('updateSession', data.searchbox);

				// Set Selected Option from session data if not APR
				if (data.searchbox.package != 'APR') {
					self.selectedOption = data.searchbox.package;
				}

				if (self.package) {
					self.setPackage(self.package);
				} else {
					self.setPackage(self.query.package);
				}

			} else {
				if (self.package) {
					self.setPackage(self.package)
				} else {
					self.setPackage('APR');
				}
			}
		})
	},
	
	methods: {
		initSearchboxTooltips() {
			setTimeout(() => {
				$('.date_input input[data-toggle="tooltip"]').tooltip()
			}, 100)
		},
		
		formatDate(date) {
			return moment(date, 'YYYY-MM-DD').format('MMM D, YYYY')
		},
		
		triggerSearchboxEvent(action, label = '') {
			dataLayer.push({
				'event': 'GaEvent',
				'EventCategory': apr.event_category,
				'EventAction': action,
				'EventLabel': label
			});
		},

		setPackage(pckg) {
			this.$store.dispatch('updatePackage', pckg)

			if (pckg === 'APR') {
				this.$store.dispatch('updateTab', 'parking')
				$('.parking-tab').addClass('active');
				$('.hotel-tab, .shuttle-tab').removeClass('active');

			} else if (pckg === 'SF') {
				this.$store.dispatch('updateTab', 'shuttle')
				$('.shuttle-tab').addClass('active');
				$('.parking-tab, .hotel-tab').removeClass('active');

			} else {
				this.$store.dispatch('updateTab', 'hotel')
				$('.hotel-tab').addClass('active');
				$('.parking-tab, .shuttle-tab').removeClass('active');
			}
		},
	}
}
