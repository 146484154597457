<template>
    <div class="accordion" :id="id">
		<h3 v-if="title" v-html="title"></h3>
        <div v-for="(item, index) in contents" class="card">
            <div class="card-header" :id="`${id}Heading${index}`">
                <h2 class="mb-0">
                    <button @click="expand(item)" class="btn btn-link" type="button" data-toggle="collapse" :data-target="`#${item.slug}`" aria-expanded="true" :aria-controls="`${item.slug}`">
                        {{ item.question }}
                    </button>
                </h2>
            </div>

            <div :id="`${item.slug}`" class="collapse" :aria-labelledby="`${id}Heading${index}`" :data-parent="`#${id}`">
                <div class="card-body" v-html="item.answer"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                type: String,
                default: 'Accordion'
            },

	        title: {
		        type: String,
		        default: ''
	        },

            contents: {
                type: Array,
                default: [],
            }
        },

        mounted() {
            this.parseHash();

            // Open first FAQ
            setTimeout(() => {
                if (_.isEmpty(location.hash)) {
                	if(this.contents && this.contents[0]) {
		                let slug = this.contents[0].slug;
		                $(`#${slug}`).collapse('show');
	                }
                }
            }, 100);
        },

        methods: {
            expand(item) {
                // Tracking Event
                this.trackEventGlobal('FAQ Airport', item.question);

                // Updating windows hash
	            history.replaceState(undefined, undefined, "#" + item.slug)
            },

            // Parsing windows hash
            parseHash: function () {
                let slug = location.hash;

                if (!_.isEmpty(slug)) {
                    $(`${slug}`).collapse('show');
	                $('html,body').animate({scrollTop: $(`${slug}`).offset().top - 200}, 1000);
                }
            },
        },
    }
</script>

