<template>
	<section id="flightstatus-search" v-if="$parent.searchboxEdit">
		<div class="container">

				<!-- Flight Airline -->
				<div class="item airline" :class="{ 'error': showErrors && !airline.selected.code }">
					<i class="fas fa-plane item-icon"></i>
					<label>Airline</label>
					<span class="null-text" v-if="!airline.selected.code">Select Airline</span>
					<select name="airline" id="airlineSelect" :class="{ 'null': !airline.selected.code }" v-model="airline.selected.code">
						<template v-for="item in airlines">
						<option :value="item.code">{{ item.name }} ({{ item.code.toUpperCase() }})</option>
						</template>
					</select>
				</div>

				<!-- Flight Departure Date -->
				<div class="item departure-date"  @click.prevent="showDatepicker()">
					<i class="far fa-calendar item-icon"></i>
					<label>Flight Departure Date</label>
					<input type="text" :id="[variable + '-date']" placeholder="Select Date"
						    v-model="datepicker_value" :name="variable" readonly/>
				</div>

				<datepicker
						:ref="'datepicker_' + variable"
						:variable="variable"
						:show-close="true"
						:show-clear="true"
						:single-date="true"
						:autoclose="false"
						:min-date="minDate"
						:max-date="maxDate"
						:pre-select-min="false"
				></datepicker>

				<!-- Flight Number -->
				<div class="item flight-number">
					<i class="fas fa-hashtag item-icon"></i>
					<label>Flight Number</label>
					<div class="flightnumber">
						<input class="w-100 border-0 rounded" type="text" placeholder="Enter flight number" v-model="flight_number">
					</div>
				</div>

				<!-- Submit -->
				<div class="item">
					<div class="submit">
						<button type="submit" class="btn btn-secondary d-block w-100 submit" @click.prevent="search(false)">
							<span v-if="!working">Search</span>
							<span v-else class="fa fa-spinner fa-spin"></span>
						</button>
					</div>
				</div>
			</div>
	</section>

	<section id="flightstatus-view" v-else>
		<div class="container px-4">
			<h1>Flights with {{ airline.default.name }}</h1>
			<h4>
				Departure Date<br>
				<span class="font-weight-lighter">{{ formatDate(datepicker_value) }}</span>
			</h4>
			<a href="#" @click.prevent="$parent.searchboxEdit = true">Edit Search</a>
		</div>
	</section>

</template>
<script>
	import FlightStatusMixin from '../../../../shared/js/FlightStatusMixin'
	import Datepicker from 'vue-datepicker-apr'

	export default {
		mixins: [ FlightStatusMixin ],
		components: { Datepicker },

		data() {
			return {
				searchboxType: 'airline',
				tab: 'airline',
				variable: 'departure',
			}
		},
	}

</script>
