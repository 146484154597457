Vue.component('page-help-faq', {

    data() {
        return {
            // Search
            query: '',
            current: '',
            limit: 100,
            results: {
                show: false,
                selected: '',
                list: null,
            },

            // Questions
            tabSelected: 'APR',
            questions: {
                opened: '',
                origin: apr.apr_faqs,
                filtered: null,
                selected: null,
            },
        }
    },

    mounted() {
        this.hash()
        this.create_list_of_questions()
    },

    watch: {
        tabSelected(tab){
            if (tab === 'APR') { this.questions.origin = apr.apr_faqs }
            else if(tab === 'PSF') { this.questions.origin = apr.psf_faqs }
            this.create_list_of_questions();
            this.questions.opened = '';
        },
    },

    computed: {
        resultMatches() {
            let self = this
            let results = null

            results = _.filter(this.questions.filtered, (suggest) => {
                return suggest.question.toLowerCase().indexOf(self.query.toLowerCase()) >= 0;
            });

            return results.splice(0, 5);
        },

        // questionMatches() {
        //     let self = this
        //     let results = _.filter(this.questions.filtered, (suggest) => {
        //         if (suggest.name == '10 Most Popular') { return false; }
        //         return suggest.question.toLowerCase().indexOf(self.results.selected.toLowerCase()) >= 0;
        //     })
        //     return results.slice(0, this.limit);
        // },
    },

    methods: {
        toggleDropdown(bool) {
            setTimeout(() => {
                this.results.show = bool;
            }, 100)
        },

        create_list_of_questions(){
            this.questions.filtered = []
            for (let i = 0; i < this.questions.origin.length; i++) {
                for (let f = 0; f < this.questions.origin[i]['data'].length; f++) {
                    this.questions.filtered.push({
                        'category': this.questions.origin[i],
                        'id': this.questions.origin[i]['data'][f]['id'],
                        'question': this.questions.origin[i]['data'][f]['question'],
                        'answer': this.questions.origin[i]['data'][f]['answer']
                    })
                }
            }
        },

        resultClick(result){
            this.selectedQuestion(result);
            this.questions.opened = result.category.id;
            this.results.show = false;
            this.query = '';
        },

        toggleQuestionType(id) {
            if(this.questions.opened === id) { this.questions.opened = null }
            else { this.questions.opened = id }
        },

        selectedQuestion(question) {
            this.questions.selected = question
            $('#help-item-modal').modal('toggle')
        },

        hash() {
            this.setActiveTab()
            window.onhashchange = this.setActiveTab
        },

        setActiveTab() {
            if (window.location.hash !== '') {
                let section  = `${window.location.hash}-content`
                $('.nav-tabs a[href="' + section + '"]').tab('show');
                $('html, body').animate({scrollTop: 0}, 500);
            } else {
                $('.nav-tabs a:first').tab('show')
            }
        },
    }
});
