<template>
	<!-- FIELDS -->
	<form action="" method="post" autocomplete="off" ref="searchboxForm">
		<option-apr v-if="currentTab === 'parking'"></option-apr>
		<option-psf v-else-if="currentTab === 'hotel'"></option-psf>
		<option-sf v-else-if="currentTab === 'shuttle'"></option-sf>
        <option-placeholder v-else></option-placeholder>
	</form>
</template>

<script>
	import SearchboxMixin from "./SearchboxMixin";
	import RequestMixin from "./Requests.js";
	import OptionAPR from "./options/OptionsAPR.vue";
	import OptionPSF from "./options/OptionsPSF.vue";
	import OptionSF from "./options/OptionsSF.vue";
	import OptionPlaceholder from "./options/OptionsPlaceholder";
	import Datepicker from './../datepicker'

	export default {
		mixins: [SearchboxMixin, RequestMixin, Datepicker],
		components: {'option-apr': OptionAPR, 'option-psf': OptionPSF, 'option-sf': OptionSF, OptionPlaceholder},
		data() {
			return {
				type: 'searchbox',
				showDates: false,
			}
		},

		mounted() {
			// hide searchbox placeholder on vue load
			$("#landing .searchbox-placeholder").remove();

			this.initSearchboxForm()
			this.initSetTab()
		},

		watch: {
			'query.date1': function (val) {
				this.showDates = !!val
			},
			'selectedOption': function (val) {
				let select = $('.sleep select')
				if (select.parsley()) {
					$('.sleep').removeClass('error')
					select.parsley().reset()
				}
			}
		},

		methods: {
			initSetTab() {
        if(this.showHp){
          $('.hotel-tab').show()
        }
        else {
          $('.hotel-tab').hide()
        }
				if (this.showSf) {
					$('.shuttle-tab').show()
				} else {
					$('.shuttle-tab').hide()
				}

				$('.parking-tab').on('click', () => {
					$('.parking-tab').addClass('active')
					$('.hotel-tab, .shuttle-tab').removeClass('active')
					this.$store.dispatch('updatePackage', 'APR')
					this.$store.dispatch('updateTab', 'parking')
					this.setPageHeading('apr')
				})

				$('.hotel-tab').on('click', () => {
					$('.hotel-tab').addClass('active')
					$('.parking-tab, .shuttle-tab').removeClass('active')
                    this.$store.dispatch('updatePackage', 'PSF')
					this.$store.dispatch('updateTab', 'hotel')
					this.setPageHeading('psf')
				})

				$('.shuttle-tab').on('click', () => {
					$('.shuttle-tab').addClass('active')
					$('.parking-tab, .hotel-tab').removeClass('active')
					this.$store.dispatch('updatePackage', 'SF')
					this.$store.dispatch('updateTab', 'shuttle')
					this.setPageHeading('SF')
				})
			},

			setPageHeading(tab) {
				if (!this.h1) {
					var h1 = document.querySelector('#hero .heading h1');
					var h2 = document.querySelector('#hero .heading h2');
					if (this.headings) {
						h1.innerHTML = this.headings[tab]['h1'];
						h2.innerHTML = this.headings[tab]['h2'];
					}
				}
			},

			getPageHeading(tagName) {
				if (this.currentTab === 'hotel') {
					return this.headings.psf[tagName]
				}
				if (this.currentTab === 'shuttle') {
					return this.headings.SF[tagName]
				}
				return this.headings.apr[tagName]
			},
		}
	};
</script>
