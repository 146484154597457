<template>
    <div class="options row m-0 sf-options">
        <airport-dialog-box></airport-dialog-box>
        <label class="section pickup">
            <div class="section_icon"><i class="fa fa-map-marker-alt"></i></div>
            <algolia-google-typeahead-s-f title="Pick Up Location" variable="pickup" placeholder="Enter Pick Up Location" :is-shuttlefinder="true"></algolia-google-typeahead-s-f>
        </label>

        <label class="section dropoff">
            <div class="section_icon"><i class="fa fa-map-marker-alt"></i></div>
            <algolia-google-typeahead-s-f title="Drop Off Location" variable="dropoff" placeholder="Enter Drop Off Location" :is-shuttlefinder="true"></algolia-google-typeahead-s-f>
        </label>

        <div class="col-12 p-0 d-flex">
            <label class="section mr-3 guests-container">
                <div class="section_icon"><i class="fas fa-user"></i></div>
                <select name="guests" v-model="$parent.shuttlefinder.passengers">
                    <option :value="n" v-for="n in 16">{{ n }}</option>
                </select>
                <span class="font-weight-normal">Guest{{ $parent.shuttlefinder.passengers == 1 ? '' : 's'}}</span>
            </label>

            <!-- <label class="section return-trip">
                <div>
                    <div class="label">Book Return Trip?</div>
                    <bootstrap-toggle v-model="isRoundTrip"></bootstrap-toggle>
                </div>
            </label> -->
        </div>

        <date-range-picker :value="datetime" variable="datetime" show-two-labels required></date-range-picker>

        <div class="form-check-label custom-control custom-switch apr-custom-switch">
            <input type="checkbox" id="returnTripCheckbox" class="custom-control-input" v-model="isRoundTrip" />
            <label for="returnTripCheckbox" class="custom-control-label">Book Return Trip?</label>
        </div>

        <date-range-picker :value="returndatetime" variable="returnDatetime" show-two-labels v-if="isRoundTrip"></date-range-picker>

        <div class="button">
            <button type="submit" class="btn-brand-primary">
                <span v-show="$parent.inProgressSearch" class="btn-brand-primary__loading-container">
                  <span class="btn-brand-primary__loading"></span>
                </span>
                <span v-if="$parent.type === 'searchbox'">
                    SEARCH
                    <i class="apr-arrow-right" aria-hidden="true"></i>
                </span>
            </button>
			<div class="powered-by text-white text-uppercase font-weight-lighter text-center">
				<small>Powered By <a href="https://www.shuttlefinder.com/" rel="nofollow" class="font-weight-normal" style="color: #34b4f5;">Shuttlefinder</a></small>
			</div>
        </div>
    </div>
</template>

<script>
    import Datepicker from './../../datepicker'
    import DateRangePicker from '../../../components/Searchbox/components/DatetimePicker.vue';
    import BootstrapToggle from "../../../components/BootstrapToggle";
    import AlgoliaGoogleTypeaheadSF from '../components/AlgoliaGoogleTypeaheadSF'
    import AirportDialogBox from '../components/_airportDialogBox'
    import * as VueGoogleMaps from 'vue2-google-maps'

    export default {
        mixins: [ Datepicker ],
        components: { BootstrapToggle, DateRangePicker, AlgoliaGoogleTypeaheadSF, AirportDialogBox },
        data(){
            return {
                isRoundTrip: false,
                datetime: '',
                returndatetime: '',
            }
        },

        mounted(){
            setTimeout(()=> {
                Vue.use(VueGoogleMaps, {load: window.googleMapsConfig})
            }, 500);
        },

        watch: {
            isRoundTrip(val){
                this.$parent.$store.dispatch('updateReturn', val)
            }
        },
    };
</script>

<style lang="scss">
    .input-group-prepend.after{
        position: absolute;
        right: 1.5rem;
    }
    .return{
        font-size: 0.75rem;
        .toggle.btn{
            max-height: 38px;
            max-width: 100%;
            border-radius: 5px;
            overflow: hidden;
            .toggle-off{
                color: #aaa !important;
                font-weight: normal;
            }
        }
    }
</style>
