Vue.component('page-customer-reservations', {

    data: function () {
        return {
            customer: apr.customer,
            showOpened: true,
            showPast: false,
            tabData: apr.opened,

            modalInfo: {},
            modalResendAction: null,
            modalType: null,
            modalInput: null,
            modalReservationId: null,

            modalSuccessMessage: null,
            modalErrorMessage: null,

            modalCancellationAction: null,
            modalCancellationInProgress: false
        }
    },

    mounted() {
        this.initModalForm()
    },

    watch: {},

    methods: {
        setTab(tab){
            if (tab == 'opened') {
                this.showOpened = true
                this.showPast = false
                this.tabData = apr.opened
            } else {
                this.showOpened = false
                this.showPast = true
                this.tabData = apr.past
            }
        },

        showReceiptModal(type, reservation){
            this.modalSuccessMessage = null
            this.modalResendAction = reservation.resendAction
            this.modalReservationId = reservation.id
            this.modalType = type

            if (type == 'email') {
                this.modalInput = apr.customer.email
                this.modalInfo = {
                    'title': 'EMAIL',
                    'body': 'email address',
                    'placeholder': 'Email',
                }

            } else if (type == 'fax') {
                this.modalInput = ''
                this.modalInfo = {
                    'title': 'FAX',
                    'body': 'fax number',
                    'placeholder': 'Fax number',
                }
            }

            $('#modal-receipt').modal('show')
        },

        initModalForm(){
            let self = this
            let requesting = false
            let $form = $('#modal-receipt form')

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {
                if (requesting) {
                    return false;
                }
                requesting = true;
                self.modalSuccessMessage = null
                self.modalErrorMessage = null

                self.$http.post(self.getModalResendAction(), self.getDataForReceipt()).then(response => {
                    self.modalSuccessMessage = response.data.message
                    requesting = false;

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.modalErrorMessage = self.filters.formatMessage(errors[errorName])
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getModalResendAction() {
            return this.modalResendAction
        },

        getDataForReceipt() {
            if (this.modalType == 'email') {
                return {type: this.modalType, email: this.modalInput}
            }
            return {type: this.modalType, fax: this.modalInput}
        },


        showCancellationModal(reservation) {
            this.modalCancellationAction = reservation.cancellationAction
            this.modalReservationId = reservation.id

            this.modalSuccessMessage = false
            this.modalErrorMessage = false
            this.modalCancellationInProgress = false

            $('#modal-cancel').modal('show')
        },

        cancelReservation() {
            let self = this

            if (self.modalCancellationInProgress) {
                return false;
            }
            self.modalCancellationInProgress = true;
            self.modalSuccessMessage = null
            self.modalErrorMessage = null

            self.$http.delete(self.modalCancellationAction).then(response => {
                self.modalSuccessMessage = response.data.message
                self.modalCancellationInProgress = false;

                // hide cancel button
                for(var key in apr.opened) {
                    if (apr.opened[key].id == self.modalReservationId) {
                        apr.opened[key].cancelled = true
                    }
                }
                self.setTab('opened');
                dataLayer.push({'event': 'GaEvent', 'EventCategory':'Customer', 'EventAction': 'Cancel Reservation'});

            }, response => {
                let errors = response.body
                for (var errorName in errors) {
                    self.modalErrorMessage = self.filters.formatMessage(errors[errorName])
                }
                self.modalCancellationInProgress = false;
            });
        }
    }
});
