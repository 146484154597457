import FlightstatusSearch from '../components/FlightStatus/FlightStatusSearchboxMulti'
import Heading from '../components/heading'

Vue.component('page-flightstatus', {

    components: { FlightstatusSearch },

    mixins: [Heading],

    data() {
        return {
            heading: null
        }
    },

    mounted() {
        this.heading = this.getQueryVariable('utm_term')
    },
});
