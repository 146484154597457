import FlightstatusSearch from '../../components/FlightStatus/FlightStatusSearchboxAirport'
import FSFormater from '../../mixins/FSFormater'

Vue.component('page-flightstatus-airport', {

    components: { FlightstatusSearch },

    mixins: [ FSFormater ],

    data() {
        return {
            results: {},
            type: apr.type,
            isLoading: false,
	        searchboxEdit: true,
	        airport: apr.content.content.content,
	        information: apr.content,
	        hasSearched: false,
        }
    },
	
	watch: {
		'isLoading': function () {
			this.hasSearched = true;
		},
		'searchboxEdit': function () {}
	},

    mounted() {},
});