import "./datepicker-vendor";

export default {
    methods: {
        initSearchboxDatepickers($form, args = {}) {
            let self = this
            let $sdate = $form.find("input[name='checkindate']");
            let $edate = $form.find("input[name='checkoutdate']");
            let $dates = $form.find(".dates");

            // tab navigation
            $($sdate).add($edate).on("keyup", function () {
                $dates.data("dateRangePicker").openAndSetActive($(this).data("field"));
            });

            setTimeout(function () {
                $form.find("button.dropdown-toggle").on("keyup click", function (e) {
                    if ($dates.data("dateRangePicker").isOpened()) {
                        $dates.data("dateRangePicker").close();
                    }
                });
            }, 100);


            let options = {
                format: "MMM D, YYYY",
                showTopbar: true,
                duration: 0,
                stickyMonths: true,
                singleMonth: false,
                autoClose: true,
                minDays: 2,
                startDate: moment().format("MMM D, YYYY"),
                setValue: function (s, s1, s2) {

                    let date1 = (s1 != "Invalid date") ? s1 : ''
                    let date2 = (s2 != "Invalid date") ? s2 : ''

                    $sdate.val(date1)
                    $edate.val(date2)
    
                    $('.date-picker-wrapper .type .checkin').attr('data-date', date1)
                    $('.date-picker-wrapper .type .checkout').attr('data-date', date2)

                    self.setDates(date1, date2)

                    $sdate.triggerHandler("change")
                    $edate.triggerHandler("change")

					$sdate.blur()
					$edate.blur()
                }
            };
            $.extend(options, args);

            $dates.dateRangePicker(options).bind("datepicker-opened", function () {
                self.destroyTooltips($form);
                self.removeErrors($form);
				setTimeout(() => {
					$sdate.blur()
					$edate.blur()
				}, 1)
                $('html, body').css('overflow', 'hidden')

            }).bind("datepicker-closed", function () {
                // if ($form.data("type") == "page") {
                self.initTooltips($form);
                $('html, body').removeAttr("style")
                // }
            });
        },

        initDatepicker($field, args = {}) {
            let self = this

            let options = {
                autoClose: true,
                singleDate: true,
                showShortcuts: false,
                showTopbar: false,
                singleMonth: true,
                duration: 0,
                startDate: moment().format("MMM D, YYYY"),
                format: "MMM D, YYYY",

                setValue: function (s, date) {
                    self.setDate(date, $field.prop('name'))
                    $field.val(date)
                }
            };

            $.extend(options, args);
            $field.dateRangePicker(options)
            .bind('datepicker-closed', () => {
                $field.parsley().reset()
            })
        },

        removeErrors($form) {
            let $sdate = $form.find("input[name='checkindate']");
            let $edate = $form.find("input[name='checkoutdate']");

            $('.date1').removeClass('error')
            $('.date2').removeClass('error')
            if($('.date1').parsley()){ $('.date1').parsley().reset()}
            if($('.date2').parsley()){ $('.date2').parsley().reset()}

            $sdate.parsley().reset()
            $edate.parsley().reset()
        },

        initTooltips: function ($form) {
            let isiPad = navigator.userAgent.match(/iPad/i) != null;
            if (!isiPad) {
                let form = $form || false;
                let params = {
                    animation: false,
                    container: "body",
                    trigger: "hover"
                };

                if (form) {
                    $(form).find("[data-toggle='tooltip']").tooltip(params);

                } else {
                    $("[data-toggle='tooltip']").tooltip(params);
                }
            }
        },

        destroyTooltips: function ($form) {
            let form = $form || false;

            if (form) {
                $(form).find("[data-toggle='tooltip']").tooltip("dispose");

            } else {
                $("[data-toggle='tooltip']").tooltip("dispose");
            }
        },
    }
}
