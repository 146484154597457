/**
 *  Load Vue Components
 */
require('./airports')
require('./flightstatus')
require('./checkout')
require('./directions')
require('./landing')
require('./lot')
require('./new-landing')
require('./black-friday')
require('./seasonal')
require('./search')
require('./survey')
require('./unsubscribe')
require('./receipt')

require('./customer/customer-profile')
require('./customer/customer-reservations')

require('./help/faq_and_menu')
require('./help/receipt')
require('./help/cancellation')
require('./help/contact')

require('./flightstatus/airline')
require('./flightstatus/airport')
require('./flightstatus/information')

require('./psf/all')
require('./shuttle/all')
require('./intermediate')
require('./intermediate-psf')

require('./password-reset')
