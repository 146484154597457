<template>
	<div>
		<section id="directions-search" v-show="searchEdit">
			<div class="container">

				<form ref="searchboxForm">
					<!-- Airport -->
					<div class="item airport" :class="{ 'error': showErrors && !airport.code }">
						<i class="fas fa-ship item-icon" v-if="isCruiseport"></i>
						<i class="fas fa-plane item-icon" v-else></i>
						<label>{{ isCruiseport ? 'Cruiseport' : 'Airport' }}</label>
                        <algolia-typeahead
                                id="searchbox-airport"
                                name="searchbox-airport"
                                ref="algoliaTypeahead"
                                :airport="airport"
                                class="direction-search-typeahead"
                                :placeholder="isCruiseport ? 'Enter cruise port' : 'Enter airport'"
                                v-on:typeahead-change="setAirport"
                        ></algolia-typeahead>
					</div>
					<!-- Direction -->
					<div class="item direction" :class="{ 'error': showErrors && !direction }">
						<i class="fas fa-map-signs item-icon"></i>
						<label>To or From</label>
						<select name="direction" v-model="direction" :class="{ 'null': !direction || direction === 'null' }">
							<option value="null" selected="selected">Going or coming from?</option>
							<option value="fromAirport">Coming from {{ airport.code }}</option>
							<option value="fromAddress">Going to {{ airport.code }}</option>
						</select>
					</div>
					<!-- Address -->
					<div class="item address" :class="{ 'error': showErrors && !address }">
						<i class="fas fa-map-marker-alt item-icon"></i>
						<label>Address</label>
						<algolia-google-typeahead-s-f title="Address" variable="address" placeholder="Enter Address" required></algolia-google-typeahead-s-f>
					</div>

					<!-- Submit -->
					<div class="item">
						<div class="submit">
							<button type="submit" class="btn btn-secondary d-block w-100 submit px-5" @click.prevent="search">
								<span v-if="!working">Search Directions</span>
								<span v-else class="fa fa-spinner fa-spin"></span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</section>

		<section id="directions-view"  v-show="!searchEdit">
			<div class="container px-4">
				<h1>Your Directions</h1>
				<h4>
					<div class="bold">{{ this.direction === 'fromAirport' ? 'To' : 'From' }}</div>
					<span class="font-weight-lighter" style="font-size: 1.125rem">{{ this.address.name}}</span>
				</h4>
				<a href="#" @click.prevent="searchEdit = true">Edit Search</a>
			</div>
		</section>
	</div>
</template>

<script>
	import AlgoliaTypeahead from './Searchbox/components/AlgoliaTypeahead';
	import AlgoliaGoogleTypeaheadSF from '../components/Searchbox/components/AlgoliaGoogleTypeaheadSF';
	import * as VueGoogleMaps from 'vue2-google-maps'

	export default {
		components: {AlgoliaTypeahead, AlgoliaGoogleTypeaheadSF},

		data() {
			return {
				working: false,
				showErrors: false,
				searchEdit: true,

                defaultAirport: _.get(window, 'apr.airport'),
                airport: {
                    name: null,
                    code: null,
                    type: null,
                },
				direction: null,
				address: '',

                isCruiseport: false,
            }
		},

		mounted() {
            if (_.get(window, 'apr.airport')) {
                this.airport = _.get(window, 'apr.airport');

                this.$nextTick(() => {
                    this.$refs.algoliaTypeahead.preSelectAirport();
                })
            }

			setTimeout(() => {
				Vue.use(VueGoogleMaps, {load: window.googleMapsConfig})
			}, 500);
		},

        watch: {
            'airport.type': function (airportType) {
                airportType = _.toLower(airportType);

                console.log(airportType)
                
                // update searchbox on airport type change
                if (_.indexOf(['airport', 'cruiseport'], airportType) !== -1) { // airport & cruiseport
                    if (airportType === 'cruiseport') {
                        this.isCruiseport = true
                    } else {
                        this.isCruiseport = false
                    }
                }
            },
        },

		methods: {
			setAirport(airport) {
				this.airport = airport;
			},

			search() {
				if (this.airport.code == '') {
					$('#directions-search .item.airport').addClass('error')
				} else {
					$('#directions-search .item.airport').removeClass('error')
				}

				if (this.direction == null) {
					$('#directions-search .item.direction').addClass('error')
				} else {
					$('#directions-search .item.direction').removeClass('error')
				}

				if (this.address == '') {
					$('#directions-search .item.address').addClass('error')
				} else {
					$('#directions-search .item.address').removeClass('error')
				}

				if(this.airport.code == '' || this.direction == null || this.address == ''){
					return
				}

				const origin = (this.direction === 'fromAirport' ? this.airport.name : this.address.name).replace(/ /g, "+");
				const destination = (this.direction === 'fromAirport' ? this.address.name : this.airport.name).replace(/ /g, "+");

				this.working = true;
				this.$http.post('/directions', {
					'origin' : origin,
					'destination': destination
				}).then((response) => {
					this.working = false;
					this.$parent.searchEdit = false;
					this.working = false;
					this.$parent.results = response.body.data.routes[0].legs[0].steps;
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Airport Directions',
						'EventAction': 'Searched directions',
						'EventLabel': this.airport.code
					})
				});
			}
		}
	}
</script>

<style lang="scss">

</style>
