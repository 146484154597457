<template>
    <div class="typeahead">
        <div class="label" v-if="title">{{ title }}</div>
        <input type="text"
               class="searchbox-input"
               :placeholder="placeholder"
			   autocomplete="off"
			   autocorrect="off"
			   autocapitalize="off"
			   spellcheck="false"
               name="airport"
               v-model="query"
               @click="openModal()"
               required
               readonly
        />

        <div class="typeahead-modal" v-if="showModal">
            <div class="typeahead-modal-header">
                <input type="text"
                   :id="id"
                   :name="name"
                   :class="classname"
                   :placeholder="placeholder"
                   autocomplete="off"
                   autocorrect="off"
                   autocapitalize="off"
                   spellcheck="false"
                   v-model="query"
                   @input="update($event)"

                />

                <div class="status-icons">
                    <i class="typeahead-modal-reset apr-icon-close" @click.prevent="reset(true)" v-if="query">CLEAR</i>
                    <i class="typeahead-modal-close apr-icon-close" @click.prevent="showModal = false"></i>
                    <i class="typeahead-modal-search apr-icon-search-line"></i>
                </div>
            </div>

            <div class="typeahead-list" v-if="hasItems">
                <div class="typeahead-list-item" v-for="(result, index) in items" @mousedown="hit" @mousemove="setActive(index)" @mouseover="setActive(index)">
                    <!-- <div class="dd-icon-container">
                        <i class="dd-icon" :class="getIconClass(result)"></i>
                    </div> -->
                    <i class="apr-icon-location-pin"></i>
                    <span v-html="getNameForDropdown(result)"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let algoliasearch = require('algoliasearch')
import VueTypeahead from 'vue-typeahead'

export default {
    extends: VueTypeahead,

    props: {
        airport: Object,
        id: {
            type: String,
            required: true
        },
        hitsPerPage: {
            type: Number,
            default: 6
        },
        name: String,
        classname: String,
        placeholder: String,
        title: String,
    },

    data() {
        return {
            items: [],
            query: '',
            algolia: null,
            showModal: false,
        }
    },

    mounted() {
        let self = this

        // algolia
        let client = algoliasearch(apr.algolia_id, apr.algolia_key)
        this.algolia = client.initIndex(apr.algolia_index_apr)

        this.preSelectAirport()
    },

    watch: {
        'query': function (value) {
            if (value === '') {
                this.reset()
            }
        }
    },

    methods: {
        openModal() {
            this.showModal = true;

            setTimeout(() => {
                this.focusModalInput();
            }, 100)
        },

        focusModalInput(){
            $(`#${this.id}`).focus();
        },

        update: _.debounce(function () {
            if (!this.query) {
                return this.reset()
            }

            this.fetchFromAll()
        }, 100),

        fetchFromAll() {
            this.fetchAlgolia(this.query)
        },

        fetchAlgolia(searchQuery) {
            let self = this

            let options = {
                query: searchQuery,
                hitsPerPage: this.hitsPerPage,
                filters: '(type:airport OR type:cruiseport) AND active:true AND (hierarchy.country.code:US OR hierarchy.country.code:CA)',
            }

            this.algolia.search(options).then(content => {
                self.items = (content.nbHits === 0) ? [] : content.hits;

                try {
                    if (content.query.length > 2) {
                        dataLayer.push({
                            'event': 'GaEvent',
                            'EventCategory': 'Algolia',
                            'EventAction': (content.nbHits === 0) ? 'No Result' : 'Result',
                            'EventLabel': content.query
                        });
                    }
                } catch (e) {
                }
            })
        },

        getNameForDropdown(item) {
            // return item.display.short // algolia
            return item._highlightResult.display.short.value
        },

        getIconClass(result) {
            return {
                'dd-airplane': result.type === 'airport',
                'dd-cruise': result.type === 'cruiseport',
            }
        },

        onHit(item) {
            this.items = []
            this.query = item.display.full
            this.setAirport(item)

            this.showModal = false;
        },

        reset(focus) {
            this.resetSoft();
            if (focus) {
                this.focusModalInput();
            }

            this.query = ''

            let airport = {
                city: '',
                code: '',
                name: '',
                state_code: '',
                type: '',
                latitude: '',
                longitude: '',
            };

            this.$store.dispatch('updateAirport', airport);
            this.$emit('typeahead-change', airport);
        },

        resetSoft() {
            this.items = []
        },

        focus() {
        },

        blur() {
        },

        setAirport(item) {
            let city = _.get(item, 'hierarchy.city', item.city) // from algolia or page
            let name = _.get(item, 'hierarchy.name', item.name) // from algolia or page
            let state_code = _.get(item, 'hierarchy.state.code', _.get(item, 'address.state_code')) // from algolia or page
            let latitude = _.get(item, '_geoloc.lat', _.get(item, 'geo.latitude')) // from algolia or page
            let longitude = _.get(item, '_geoloc.lng', _.get(item, 'geo.longitude')) // from algolia or page
            let airport_code = _.get(item, 'hierarchy.airport_code', item.code) // from algolia or page

            let airport = {
                'type': item.type,
                'city': city,
                'name': name,
                'state_code': state_code,
                'code': airport_code,
                'latitude': latitude,
                'longitude': longitude,
            };

            this.$store.dispatch('updateAirport', airport);
            this.$emit('typeahead-change', airport);
        },

        preSelectAirport() {
            this.$nextTick(()=>{
                if (_.get(this.airport, 'name')) {
                    if (this.airport.type === 'cruiseport') {
                        if (_.get(this.airport, 'address.state_code')) {
                            this.query = `${this.airport.name}, ${_.get(this.airport, 'address.state_code')}, Cruise Port`
                        } else {
                            this.query = `${this.airport.name}, Cruise Port`
                        }
                    } else {
                        this.query = `${this.airport.name} (${this.airport.code})`
                    }

                    this.setAirport(this.airport)
                }
            })
        }
    }
}
</script>