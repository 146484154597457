Vue.component('app-navigation', {
    data: function () {
        return {
            id: null,
            role: 'guest',
            menuOpenLevel: 1,
        };
    },

    mounted() {
        let self = this;

        this.$root.$on('session-received', function (data) {
            let login = data.login;

            self.id = login.id;
            self.role = login.role;
        });
    },

    methods: {
        showOffCanvas() {
            $('.fas.fa-bars').click();
        },
        closeNavMenu() {
            this.showOffCanvas();
            this.menuOpenLevel = 1;
        },
        setActiveItem($event) {
            const { target } = $event;
            const parent = target.closest('li');

            if (parent) {
                parent.classList.add('is-active');
                this.menuOpenLevel += 1;
            }
        },
        menuBack() {
            const menu = this.$refs['mobile-nav'];
            const lastOpenMenu = [...menu.querySelectorAll('.is-active')].pop();
            if (menu && lastOpenMenu) {
                lastOpenMenu.classList.remove('is-active');
                this.menuOpenLevel -= 1;
            }
        },
    },
});
