<template>
	<div class="options row m-0 flex-xl-nowrap">
		<label class="section airport" v-if="$parent.type !== 'searchbox-hotel'">
			<algolia-typeahead
				id="searchbox-airport"
				name="searchbox-airport"
				ref="algoliaTypeahead"
				:airport="query.airport"
				:placeholder="$parent.isCruiseport ? 'Enter departure cruise port' : 'Enter departure airport'"
				:title="$parent.isCruiseport ? 'Departure cruise port' : 'Departure airport'"
			></algolia-typeahead>
		</label>

		<label class="section sleep">
			<div class="section_icon"><i class="apr-icon-bed"></i></div>
			<div class="label">Choose which night to sleep</div>
			<select name="which_nights" v-model="$parent.selectedOption" :class="{ 'null': !$parent.selectedOption || $parent.selectedOption === 'null' }" required data-parsley-is-null="">
				<option value="null" selected="selected">Choose which night to sleep</option>
				<option value="PSF">At the beginning of trip</option>
				<option value="PFS">At the end of trip</option>
				<option value="PSFS">At the beginning & end of trip</option>
			</select>
		</label>

		<div class="col-12 col-xl p-0 m-0 dates" v-show="$parent.showDates">
			<label class="section date1 date_input" @click.prevent="showDatepicker('date1')">
				<div class="section_icon"><i class="apr-icon-calendar"></i></div>
				<div>
					<div class="label">Check-In</div>
					<div class="date-value">{{ formatDate(query.date1) }}</div>
					<input type="text" @blur="dateChanged('checkin')" name="checkindate" v-model="query.date1" data-field="date1" placeholder="Check-In Date"
						   data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 0)" readonly required data-parsley-required-message="Select a date">
				</div>
			</label>
			<label class="section date2 date_input" @click.prevent="showDatepicker('date2')">
				<div class="section_icon"><i class="apr-icon-calendar"></i></div>
				<div>
					<div class="label">Check-Out</div>
					<div class="date-value">{{ formatDate(query.date2) }}</div>
					<input type="text" class="pl-0" @blur="dateChanged('checkout')" name="checkoutdate" v-model="query.date2" data-field="date2" placeholder="Check-Out Date"
						   data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 1)" readonly required data-parsley-required-message="Select a date">
				</div>
			</label>
		</div>

		<label class="section no-dates" @click.prevent="showDatepicker()" v-show="!$parent.showDates">
			<div class="section_icon"><i class="apr-icon-calendar"></i></div>
			<input class="searchbox-input" type="text" placeholder="Select hotel & parking dates" readonly>
		</label>

		<datepicker
				ref="datepicker"
				:show-close="true"
				:show-clear="true"
				:autoclose="false"
				:placeholders="{ 'date1': 'Hotel Check-In', 'date2': 'Hotel Check-Out'}"
		></datepicker>

		<div class="col-12 col-xl p-0 row m-0 guests">
			<label class="section">
				<div class="section_icon"><i class="apr-icon-user"></i></div>
				<div class="label">Guest{{ query.guests == 1 ? '' : 's'}}</div>
				<select name="guests" v-model="query.guests">
					<option :value="n" v-for="n in 16">{{ n }}</option>
				</select>
			</label>

			<label class="section">
				<div class="section_icon"><i class="apr-icon-bed"></i></div>
				<div class="label">Room{{ query.rooms === 1 ? '' : 's'}}</div>
				<select name="guests" v-model="query.rooms">
					<option :value="n" v-for="n in 6">{{ n }}</option>
				</select>
			</label>
		</div>

		<div class="button">
			<button type="submit" class="btn-brand-primary">
				<span v-if="$parent.type === 'searchbox'">SEARCH PACKAGE</span>
				<span v-if="$parent.type !== 'searchbox'">UPDATE PACKAGE</span>
				<span v-show="$parent.inProgressSearch" class="btn-brand-primary__loading-container" aria-hidden="true">
					<span class="btn-brand-primary__loading"></span>
				</span>
				<i class="apr-arrow-right" aria-hidden="true"></i>
			</button>
			<span class="cancel" v-if="$parent.isEditMode && $parent.passedOption === 'PSF'">
				<a href="#" @click.prevent="$parent.isEditMode = false" class="text-link">Cancel</a>
			</span>
		</div>
	</div>
</template>

<script>
	import AlgoliaTypeahead from '../components/AlgoliaTypeahead'
	import Datepicker from '../../VueDatePicker/DatePicker.vue'
    import { mapGetters, mapActions } from 'vuex'

	export default {
		components: {AlgoliaTypeahead, Datepicker},
		data() {
			return {
				sleepOptionOpen: false,
			}
		},

        mounted() {
			if ((apr && apr.search) || (apr && apr.search_psf)) {
				this.selectedOption = this.query.package
			}
		},

		computed: {
            ...mapGetters([
                'session', 'query',
            ]),

			dates: {
				get() {
					if(this.query.date1 || this.query.date2) {
						return {
							date1: moment(this.query.date1).format('ddd MMM DD YYYY'),
							dateISO1: moment(this.query.date1).toDate(),
							date2: moment(this.query.date2).format('ddd MMM DD YYYY'),
							dateISO2: moment(this.query.date2).toDate()
						};
					}else{
						return {
							date1: null,
							dateISO1: null,
							date2: null,
							dateISO2: null
						};
					}
				},
				set(val) {
					this.$parent.$store.dispatch('updateDates', {date1: val.date1, date2: val.date2});
				}
			}
		},
		watch: {
			selectedOption(val) {
				this.selectSleepOption(val)
			}
		},
		methods: {
			getFieldTitle(_package, _index) {
				if (!_package) {
					_package = 'PSF'
				}
				return this.$parent.titles[_package].dates[_index]
			},

			getFieldTooltip(_package, _index) {
				if (!_package) {
					_package = 'PSF'
				}
				return this.$parent.titles[_package].tooltips[_index]
			},

			getPackageType() {
				if (this.currentTab === 'hotel') {
					return 'PSF'
				}
				if (this.currentTab === 'shuttle') {
					return 'SF'
				}
				return 'APR'
			},

			selectSleepOption(option) {
				this.sleepOptionOpen = false;
				this.$store.dispatch('updatePackage', option)
			},

			dateChanged(when) {
				if (when === 'checkin') {
					this.triggerSearchboxEvent(apr.event_category, `Check-in date entered ${this.$parent.currentTab}`, '')
				} else {
					this.triggerSearchboxEvent(apr.event_category, `Check-out date entered ${this.$parent.currentTab}`, '')
				}
			},

			formatDate(val) {
				return moment(val).format('ddd, MMM D');
			},

			triggerSearchboxEvent(action, label = '') {
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': apr.event_category,
					'EventAction': action,
					'EventLabel': label
				});
			},

			showDatepicker(date = 'date1') {
				this.$refs.datepicker.openModal(date)
			},
		}
	};
</script>
