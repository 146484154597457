import Heading from '../components/heading'

Vue.component('page-airports', {

    mixins: [Heading],

    data() {
        return {
            heading: null
        }
    },

    mounted() {
        this.heading = this.getQueryVariable('utm_term')
    },

});
