<template>
	<div class="options row m-0">
		<label class="section airport" v-if="$parent.type !== 'searchbox-hotel'">
      <algolia-typeahead
        id="searchbox-airport"
        name="searchbox-airport"
        ref="algoliaTypeahead"
        :airport="query.airport"
        :placeholder="$parent.isCruiseport ? 'Cruise port location' : 'Airport location'"
        :title="$parent.isCruiseport ? 'Cruise port location' : 'Airport location'"
      ></algolia-typeahead>
		</label>

		<div class="col p-0 m-0 dates" v-show="$parent.showDates">
			<label class="section date1 date_input" @click.prevent="showDatepicker('date1')">
				<div class="section_icon"><i class="apr-icon-calendar"></i></div>
				<div>
					<div class="label">Check-In</div>
					<div class="date-value">{{ formatDate(query.date1) }}</div>
					<input type="text" @blur="dateChanged('checkin')" name="checkindate" v-model="query.date1" data-field="date1" placeholder="Check-In Date"
						   data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 0)" readonly required data-parsley-required-message="Select a date">
				</div>
			</label>
			<label class="section date2 date_input" @click.prevent="showDatepicker('date2')">
				<div class="section_icon"><i class="apr-icon-calendar"></i></div>
				<div>
					<div class="label">Check-Out</div>
					<div class="date-value">{{ formatDate(query.date2) }}</div>
					<input type="text" class="pl-0" @blur="dateChanged('checkout')" name="checkoutdate" v-model="query.date2" data-field="date2" placeholder="Check-Out Date"
						   data-toggle="tooltip" :data-original-title="getFieldTooltip(getPackageType(), 1)" readonly required data-parsley-required-message="Select a date">
				</div>
			</label>
		</div>
		<label class="section no-dates" @click.prevent="showDatepicker()" v-show="!$parent.showDates">
			<div class="section_icon"><i class="apr-icon-calendar"></i></div>
			<input class="searchbox-input" type="text" placeholder="Select parking dates" readonly>
		</label>

		<datepicker
				ref="datepicker"
				:show-close="true"
				:show-clear="true"
				:autoclose="false"
				:allow-same-date="true"
				:placeholders="{ 'date1': 'Parking Check-In', 'date2': 'Parking Check-Out' }"
        ></datepicker>

		<div class="button">
			<button v-if="isPageSpiritAirlines()" type="submit" class="btn btn-big btn-wide search-button" :style="{'min-width': $parent.type != 'searchbox-inline' ? '100px' : '180px' }">
        <span v-if="!$parent.inProgressSearch">SEARCH PARKING</span>
        <i class="fas fa-spinner fa-spin" aria-hidden="true" v-if="$parent.inProgressSearch"></i>
      </button>
			<button v-else type="submit" class="btn-brand-primary">
				<span>SEARCH PARKING</span>
				<span v-show="$parent.inProgressSearch" class="btn-brand-primary__loading-container" aria-hidden="true">
          <span class="btn-brand-primary__loading"></span>
        </span>
				<i class="apr-arrow-right" aria-hidden="true"></i>
			</button>
			<span class="cancel" v-if="$parent.isEditMode && $parent.passedOption === 'APR'"><a href="#" @click.prevent="$parent.isEditMode = false" class="text-link">Cancel</a></span>
		</div>
	</div>
</template>

<script>
	import AlgoliaTypeahead from '../components/AlgoliaTypeahead'
	import Datepicker from '../../VueDatePicker/DatePicker.vue'
    import { mapGetters, mapActions } from 'vuex'

	export default {
		components: {AlgoliaTypeahead, Datepicker},

    data() {
      return {
        currentUrl: ''
      }
    },

    created() {
      this.currentUrl = window.location.pathname;
    },

		computed: {
            ...mapGetters([
                'session', 'query',
            ]),

			dates: {
				get() {
					if(this.query.date1 || this.query.date2) {
						return {
							date1: moment(this.query.date1).format('ddd MMM DD YYYY'),
							dateISO1: moment(this.query.date1).toDate(),
							date2: moment(this.query.date2).format('ddd MMM DD YYYY'),
							dateISO2: moment(this.query.date2).toDate()
						};
					}else{
						return {
							date1: null,
							dateISO1: null,
							date2: null,
							dateISO2: null
						};
					}
				},
				set(val) {
					this.$parent.$store.dispatch('updateDates', {date1: val.date1, date2: val.date2});
				}
			}
		},
		methods: {
			getFieldTitle(_package, _index) {
				if (!_package) {
					_package = 'PSF'
				}
				return this.$parent.titles[_package].dates[_index]
			},

			getFieldTooltip(_package, _index) {
				if (!_package) {
					_package = 'PSF'
				}
				return this.$parent.titles[_package].tooltips[_index]
			},

			getPackageType() {
				if (this.currentTab === 'hotel') {
					return 'PSF'
				}
				if (this.currentTab === 'shuttle') {
					return 'SF'
				}
				return 'APR'
			},

			dateChanged(when) {
				if (when === 'checkin') {
					this.triggerSearchboxEvent(apr.event_category, `Check-in date entered ${this.$parent.currentTab}`, '')
				} else {
					this.triggerSearchboxEvent(apr.event_category, `Check-out date entered ${this.$parent.currentTab}`, '')
				}
			},

			formatDate(val) {
				let dateFormat1 = moment(val).format('ddd, MMM D')
				if (dateFormat1 !== 'Invalid date') {
					return dateFormat1;
				} else {
					return 'Select Date'
				}
			},

			triggerSearchboxEvent(action, label = '') {
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': apr.event_category,
					'EventAction': action,
					'EventLabel': label
				});
			},

			showDatepicker(date = 'date1') {
				this.$refs.datepicker.openModal(date)
			},

      isPageSpiritAirlines()
      {
        return this.currentUrl === '/spirit-airlines'
      },
		}
	};
</script>
