export default {
    data() {
        return {
            temp_filter_options: [],
            isAtRightMargin: false,
            isAtLeftMargin: true,
            sortingContainerWidth: 0,
        }
    },
    methods: {
        filterButtonClick: function (event) { // detect what you are clicking on. toggle hide if clicking button
            let self = this
            let $el = $(event.target)

            $el.toggleClass('menu-opened');
            var thisMenu = $el.siblings('.filter-menu');

            if ($el.hasClass('menu-opened')) {
                self.showFilterMenu(thisMenu);
            } else {
                self.hideFilterMenu();
            }
        },

        detectHideFilter: function () { // hide filter menu if you are click outside of the menu
            let self = this

            $(document).mouseup(function (e) {
                var container = $(".filter-button").parent();

                if (!container.is(e.target) // if the target of the click isn't the container...
                    && container.has(e.target).length === 0) // ... nor a descendant of the container
                {
                    self.hideFilterMenu();
                }
            });
        },

        showFilterMenu: function (menu) { // animate and show filter menu
            let filterMenu = menu;
            let filterParent = filterMenu.parent('.filter-container');
            let self = this

            /* this if/else if statement is to check if a current filter menu is already opened. if it is, close it.*/
            if (filterParent.hasClass('sort-by')) {
                let otherButton = $('.parking-options').find('.filter-button');

                if (otherButton.hasClass('menu-opened')) {
                    let otherMenu = $('.parking-options').find('.filter-menu');
                    self.hideSpecificMenu(otherMenu, otherButton);
                }
            }

            else if (filterParent.hasClass('parking-options')) {
                let otherButton = $('.sort-by').find('.filter-button');

                if (otherButton.hasClass('menu-opened')) {
                    let otherMenu = $('.sort-by').find('.filter-menu');
                    self.hideSpecificMenu(otherMenu, otherButton);
                }
            }
            /* end of if/else if statement */

            filterMenu.show();
            filterMenu.animate(
                {
                    opacity: 1,
                    top: '39px'
                },
                {
                    duration: 300
                }
            );
        },

        hideSpecificMenu: function (menu, button) { // this hides just one of the filters instead of hiding both
            button.removeClass('menu-opened');
            menu.animate(
                {
                    opacity: 0,
                    top: '0px'
                },
                {
                    duration: 300,
                    complete: function () {
                        $(this).hide();
                    }
                }
            );
        },

        hideFilterMenu: function () { // hide all filter menu and reset position
            let filterButton = $('.filter-button');

            filterButton.each(function () {
                $(this).removeClass('menu-opened');
            });

            $('.filter-menu').animate( // animate the filter menu disappearing then hide
                {
                    opacity: 0,
                    top: '0px'
                },
                {
                    duration: 300,
                    complete: function () {
                        $(this).hide();
                    }
                }
            );
        },

        // this function will highlight the label and add toggle checkbox that is hidden
        toggleParkingOptionCheckBox: function (filter) {

            //if filter exists in temp_filter_options
            if (this.temp_filter_options.indexOf(filter) < 0) {

                this.temp_filter_options.push(filter);
                this.temp_filter_options = this.temp_filter_options.filter((v, i, a) => a.indexOf(v) === i);

            } else {

                let index = this.temp_filter_options.findIndex(option => {
                    return (option == filter)
                });
                this.temp_filter_options.splice(index, 1);
            }

            dataLayer.push({'event': 'GaEvent', 'EventCategory':'FilterLots', 'EventAction': 'filtered', 'EventLabel': filter})
        },

        closeFilters(){
            this.temp_filter_options = this.filter_options
        },

        setFilters(){
            this.filter_options = this.temp_filter_options
            this.filterProducts();
        },

        resetFilters(){
            this.temp_filter_options = [];
            this.filterProducts();
        },

        selectSortByOption: function () { // this will select the correct option that is hdiden
            var sortByLabel = $('.sort-by-menu').find('label');
            let self = this

            sortByLabel.click(function () {
                sortByLabel.each(function () {
                    $(this).removeClass('active-label');
                });
                var value = $(this).attr('data-option');

                dataLayer.push({'event': 'GaEvent', 'EventCategory':'SortLots', 'EventAction': 'sorting', 'EventLabel': value})

                $(this).addClass('active-label');

                self.sort_option = value
                self.filterProducts()

                self.hideFilterMenu();
            });
        },

        showArrows: _.throttle(function (e) {
            const container = e.target;
            if (this.sortingContainerWidth === 0) {
                this.sortingContainerWidth = container.getBoundingClientRect().width;
            }
            const scrollPosition = container.scrollLeft;
            const scrollWidth = container.scrollWidth;
            this.isAtRightMargin = scrollPosition >= scrollWidth - this.sortingContainerWidth - 10;
            this.isAtLeftMargin = scrollPosition < 10;
        }, 100),

        scrollSorting(direction) {
            const el = this.$refs['sort-container'];
            if (el) {
                el.scrollTo({
                    left: direction === 'start' ? 500 : -500,
                    behavior: 'smooth',
                });
            }
        },
    }
}