<template>

	<section id="flightstatus-search" v-if="$parent.searchboxEdit">
		<div class="container pt-4">

			<!-- Title -->
			<h1 v-if="!headingOne">{{ airport.default.code ? airport.default.code : '' }} Airport - Live Flight Status & Delays</h1>
			<h1 v-if="headingOne">{{ headingOne }}</h1>
			<p class="text-center text-white">
				Use the form below to search for {{ airport.default.code }}
				<a class="text-tertiary font-weight-bold" :href="`/flight-status/${airport.default.code ? airport.default.code.toLowerCase() : ''}-arrivals`">arrivals</a> and
				<a class="text-tertiary font-weight-bold" :href="`/flight-status/${airport.default.code ? airport.default.code.toLowerCase() : ''}-departures`">departures</a>
			</p>

			<!-- Flight Departure Airport -->
				<div class="item airport" :class="{ 'error': showErrors && !airport.departure.code }" v-if="tab === 'airport'">
					<i class="fas fa-plane item-icon"></i>
					<label>Departure Airport</label>
                    <algolia-typeahead
                            id="airportSelect"
                            ref="algoliaTypeahead"
                            placeholder="Enter Airport"
                            class="flightstatus-page-typeahead"
                            :airport="airport.departure"
                            v-on:typeahead-change="setDepartureAirport">
                    </algolia-typeahead>
					<div class="error-message">Please select an airport.</div>
				</div>

				<!-- Flight Direction -->
				<div class="item flight-direction" :class="{ 'error': showErrors && !flight_direction }" >
					<i class="fas fa-map-signs item-icon"></i>
					<label>Arrival or Departure</label>
					<div class="dropdown">
						<button class="btn dropdown-toggle" :class="{ 'null': !flight_direction }" type="button" id="FlightDirection" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span v-if="!flight_direction">Arriving or departing from {{ airport.default.code }}?</span>
							<span v-else-if="flight_direction === 'arrivals'">Arriving at {{ airport.arrival.code }}</span>
							<span v-else-if="flight_direction === 'departures'">Departing from {{ airport.departure.code }}</span>
						</button>
						<div class="dropdown-menu" aria-labelledby="FlightDirection">
							<button class="dropdown-item" type="button" @click.prevent="flight_direction = 'arrivals'">Arriving at {{ airport.arrival.code }}</button>
							<button class="dropdown-item" type="button" @click.prevent="flight_direction = 'departures'">Departing at {{ airport.departure.code }}</button>
						</div>
					</div>
					<div class="error-message">Please select flight direction.</div>
				</div>

				<!-- Flight Arrival Airport -->
				<div class="item airport" :class="{ 'error': showErrors && !airport.arrival.code }">
					<i class="fas fa-plane item-icon"></i>
					<label>Arrival Airport</label>
                    <algolia-typeahead
                            id="airportSelect2"
                            placeholder="Enter Airport"
                            class="flightstatus-page-typeahead"
                            :airport="airport.arrival"
                            v-on:typeahead-change="setArrivalAirport">
                    </algolia-typeahead>
					<div class="error-message">Please select an airport.</div>
				</div>

				<!-- Flight Departure Date -->
				<div class="item departure-date"  @click.prevent="showDatepicker()">
					<i class="far fa-calendar item-icon"></i>
					<label>Flight Departure Date</label>
					<input type="text" :id="[variable + '-date']" placeholder="Select Date"
						   v-model="datepicker_value" :name="variable" readonly/>
				</div>

				<datepicker
						:ref="'datepicker_' + variable"
						:variable="variable"
						:show-close="true"
						:show-clear="true"
						:single-date="true"
						:autoclose="false"
						:min-date="minDate"
						:max-date="maxDate"
						:pre-select-min="false"
				></datepicker>

				<!-- Submit -->
				<div class="item">
					<div class="submit">
						<button type="submit" class="btn btn-secondary d-block w-100 submit px-5" @click.prevent="search(false)">
							<span v-if="!working">View Flight Status</span>
							<span v-else class="fa fa-spinner fa-spin"></span>
						</button>
					</div>
				</div>

		</div>
	</section>

	<section id="flightstatus-view" v-else>
		<div class="container px-4">
			<h1>Flights {{ flight_direction === 'departures' ? 'from' : 'to' }} {{ airport[flight_direction === 'departures' ? 'departure' : 'arrival'].code.toUpperCase() }} {{ flight_direction != 'departures' ? 'from' : 'to' }} {{ airport[flight_direction === 'departures' ? 'arrival' : 'departure'].code.toUpperCase() }}</h1>
			<h4>
				Departure Date<br>
				<span class="font-weight-lighter" style="font-size: 1.125rem">{{ formatDate(datepicker_fullvalue) }}</span>
			</h4>
			<a href="#" @click.prevent="$parent.searchboxEdit = true">Edit Search</a>
		</div>
	</section>

</template>
<script>
	import FlightStatusMixin from '../../../../shared/js/FlightStatusMixin'
	import AlgoliaTypeahead from '../Searchbox/components/AlgoliaTypeahead'
	import Datepicker from 'vue-datepicker-apr'

	export default {
		mixins: [ FlightStatusMixin ],
		components: { AlgoliaTypeahead, Datepicker },

		props: {
			headingOne: { type: String, required: false },
			headingTwo:{ type: String, required: false },
		},

		data() {
			return {
				searchboxType: 'airport',
				tab: 'airport',
				variable: 'departure',
			}
		},

        mounted() {
            if (this.airport.departure.code) {
                this.$refs.algoliaTypeahead.preSelectAirport();
            }
        },
	}
</script>
