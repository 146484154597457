<template>
    <div class="d-flex w-100">
        <div class="col-12 col-xl p-0 row m-0 dates" v-if="showTwoLabels">
            <label :class="variable" :for="variable + '_datepicker'" class="section date date_input" @click.prevent="showDatepicker()">
                <div class="section_icon"><i class="apr-icon-calendar"></i></div>
                <div class="datetime-container">
                    <div class="label">{{ fieldLabel[0] }}</div>
                    <input :id="variable + '_datepicker'" type="text" placeholder="Select Date" v-model="dateStr" :name="variable" readonly required data-parsley-required-message="Please select date">
                </div>
            </label>
            <label class="section time date_input" :class="variable">
                <div class="section_icon"><i class="apr-icon-time"></i></div>
                <div class="datetime-container">
                    <div class="label">{{ fieldLabel[1] }}</div>
                    <select class="custom-select" :class="{ 'is-active': time }" v-model="time" :name="variable" required
                            data-parsley-required-message="Please select time">
                        <option value="" disabled>Select Time</option>
                        <option v-for="timeOption in timeOptions" :value="timeOption[0]">{{ timeOption[1] }}</option>
                    </select>
                </div>
            </label>
        </div>

        <div class="section datetime" v-else>
            <label class="date d-flex flex-column">
                <div class="section_icon"><i class="apr-icon-calendar"></i></div>
                <div class="section_heading">{{ fieldLabel }}</div>
                <div class="input-container" ref="dateContainer">
                    <input type="text" class="form-control" placeholder="Select Date" v-model="dateStr" :data-value="date" :data-calendar-label="calendarLabel" :name="variable" readonly required
                           data-parsley-required-message="Please select date">
                    <select class="custom-select" v-model="time" :name="variable" required
                            data-parsley-required-message="Please select time">
                        <option value="" disabled>Select Time</option>
                        <option v-for="timeOption in timeOptions" :value="timeOption[0]">{{ timeOption[1] }}</option>
                    </select>
                </div>
            </label>
        </div>

        <datepicker
                :ref="'datepicker_' + variable"
                :variable="getDatetimeFieldName(variable)"
                :show-close="true"
                :show-clear="true"
                :single-date="true"
                :autoclose="false"
                :title="getDatetimeCalendarLabel()"
        ></datepicker>
    </div>
</template>

<script>
    // import Datepicker from 'vue-datepicker-apr'
    import Datepicker from '../../VueDatePicker/DatePicker.vue'

    export default {

        components: { Datepicker },

        model: {
            prop: 'time',
            event: 'change'
        },

        props: {
            variable: {
                type: String,
                required: true,
            },
            showTwoLabels: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                // text
                fieldLabel: this.getDatetimeFieldLabel(),
                calendarLabel: this.getDatetimeCalendarLabel(),

                // dropdown values
                timeOptions: this.getTimeOptions(),

                // values
                date: this.getDatePart(),
                dateStr: this.getDateStrPart(),
                time: this.getTimePart()
            }
        },

        computed: {
            datetimeComputed() {
                if (this.date && this.time) {
                    return `${this.date} ${this.time}:00`
                }
                return null
            },
            dates: {
                get() {
                    if (this.$parent.$parent.shuttlefinder[this.getDatetimeFieldName(this.variable)]){
                        return {
                            date1: moment(this.$parent.$parent.shuttlefinder[this.getDatetimeFieldName(this.variable)]).format('ddd MMM DD YYYY'),
                            dateISO1: moment(this.$parent.$parent.shuttlefinder[this.getDatetimeFieldName(this.variable)]).toDate()
                        }
                    }else{
                        return {
                            date1: null,
                            dateISO1: null,
                        }
                    }
                },
                set(val) {
                    this.value = val.date1
                    this.setNewTimeProperties()
                }
            }
        },

        watch: {
            '$parent.$parent.shuttlefinder.trip_type': function(newValue, oldValue) {
                this.fieldLabel = this.getDatetimeFieldLabel()

                // update datetime field if trip type was changed
                if (newValue !== oldValue && oldValue !== null) {
                    this.updateStoreDateField()
                }
            },

            '$parent.$parent.shuttlefinder': {
                handler() {
                    this.calendarLabel = this.getDatetimeCalendarLabel()
                },
                deep: true
            },

            date() {
                if($(this.$el).find('input').parsley() && this.time != '') {
                    $('.' + this.variable).removeClass('error')
                    $(this.$el).find('input').parsley().reset()
                }
                this.$emit('change', this.date)
            },

            time() {
                if($(this.$el).find('input').parsley() && this.date != '') {
                    $('.' + this.variable).removeClass('error')
                    $(this.$el).find('input').parsley().reset()
                }
                this.$emit('change', this.time)
            },

            datetimeComputed() {
                if (this.value !== this.datetimeComputed) {
                    this.updateStoreDateField()
                }
            },

            value() {
                this.setNewTimeProperties()
            }
        },

        mounted() {
        },

        methods: {
            showDatepicker(date = 'date1') {
                this.$refs['datepicker_' + this.variable].openModal(date)
            },

            getTimeOptions() {
                let startTime = moment().utc().set({day: 1, hour: 24, minute: 0});
                let endTime = moment().utc().set({day: 2, hour: 23, minute: 59});
                let timeStops = [];

                while (startTime <= endTime) {
                    let time = moment(startTime)
                    timeStops.push([
                        time.format('HH:mm'),
                        time.format('h:mm A')
                    ]);
                    startTime.add(15, 'minutes');
                }

                return timeStops
            },

            getDatePart() {
                if (this.value) {
                    return moment(this.value).format('YYYY-MM-DD')
                }
                return ''
            },

            getDateStrPart() {
                if (this.value) {
                    return moment(this.value).format('ddd, MMM D')
                }
                return ''
            },

            getTimePart() {
                if (this.value) {
                    return moment(this.value).format('HH:mm')
                }
                return ''
            },

            setNewTimeProperties() {
                this.date = this.getDatePart();
                this.dateStr = this.getDateStrPart();
                this.time = this.getTimePart();
            },

            updateStoreDateField() {
                // console.log(`update${this.getDatetimeFieldName(this.variable).capitalize()}`)
                this.$store.dispatch(`update${this.getDatetimeFieldName(this.variable).capitalize()}`, this.datetimeComputed)
            },

            getDatetimeFieldName(variable) {
                let tripType = this.$parent.$parent.shuttlefinder.trip_type

                if (variable === 'datetime') {
                    if (tripType !== 'nonAirport') {
                        return 'dropoff_date'
                    }
                    return 'pickup_date' // default, pointToPoint

                    // it means round trip
                } else if (variable === 'returnDatetime') {
                    if (tripType !== 'nonAirport') {
                        return 'return_dropoff_date'
                    }
                    return 'return_pickup_date'
                }
            },

            getDatetimeFieldLabel() {
                let tripType = this.$parent.$parent.shuttlefinder.trip_type
                let fieldLabel = ''

                if (this.variable === 'datetime') {
                    if (tripType === 'toAirport') {
                        fieldLabel = 'Flight Departure'
                    } else if (tripType === 'fromAirport') {
                        fieldLabel = 'Flight Arrival'
                    } else if (tripType === 'airportToAirport') {
                        fieldLabel = 'Flight Arrival'
                    } else {
                        fieldLabel = 'Pick Up' // default, pointToPoint
                    }

                    // it means round trip
                } else if (this.variable === 'returnDatetime') {
                    if (tripType === 'toAirport') {
                        fieldLabel = 'Return Flight Arrival'
                    } else if (tripType === 'fromAirport') {
                        fieldLabel = 'Return Flight Departure'
                    } else if (tripType === 'airportToAirport') {
                        fieldLabel = 'Return Flight Arrival'
                    } else {
                        fieldLabel = 'Return Pick Up'
                    }
                }

                if (this.showTwoLabels) {
                    return [`${fieldLabel} Date`, `${fieldLabel} Time`]
                }

                return `${fieldLabel} Date & Time`
            },

            getDatetimeInlineFieldLabel(variable) {
                let tripType = this.$parent.$parent.shuttlefinder.trip_type

                if (variable === 'datetime') {
                    if (tripType === 'toAirport') {
                        return 'Flight Departure Date & Time'
                    } else if (tripType === 'fromAirport') {
                        return 'Flight Arrival Date & Time'
                    } else if (tripType === 'airportToAirport') {
                        return 'Flight Arrival Date & Time'
                    } else {
                        return 'Pick Up Date & Time' // default, pointToPoint
                    }

                    // it means round trip
                } else if (variable === 'returnDatetime') {
                    if (tripType === 'toAirport') {
                        return 'Return Flight Arrival Date & Time'
                    } else if (tripType === 'fromAirport') {
                        return 'Return Flight Departure Date & Time'
                    } else if (tripType === 'airportToAirport') {
                        return 'Return Flight Arrival Date & Time'
                    } else {
                        return 'Return Pick Up Date & Time'
                    }
                }
            },

            getDatetimeCalendarLabel() {
                let tripType = _.get(this.$parent.$parent, 'shuttlefinder.trip_type')
                let airportCode1 = _.get(this.$parent.$parent, 'shuttlefinder.pickup.airport_code')
                let airportCode2 = _.get(this.$parent.$parent, 'shuttlefinder.dropoff.airport_code')

                if (this.variable === 'datetime') {
                    let defaultLabel = 'Select your pick up date'
                    if (tripType === 'toAirport') {
                        return airportCode2 ? `Select date your flight departs from ${airportCode2}` : defaultLabel
                    } else if (tripType === 'fromAirport') {
                        return airportCode1 ? `Select date your flight arrives at ${airportCode1}` : defaultLabel
                    } else if (tripType === 'airportToAirport') {
                        return airportCode2 ? `Select date your flight arrives at ${airportCode1}` : defaultLabel
                    } else {
                        return defaultLabel // default, pointToPoint
                    }

                    // it means round trip
                } else if (this.variable === 'returnDatetime') {
                    let defaultLabel = 'Select your return pick up date'

                    if (tripType === 'toAirport') {
                        return airportCode2 ? `Select date your return flight arrives at ${airportCode2}` : defaultLabel
                    } else if (tripType === 'fromAirport') {
                        return airportCode1 ? `Select date your return flight departs from ${airportCode1}` : defaultLabel
                    } else if (tripType === 'airportToAirport') {
                        return airportCode1 ? `Select date your return flight arrives at ${airportCode2}` : defaultLabel
                    } else {
                        return defaultLabel
                    }
                }
            },
        }
    }
</script>