require('./bootstrap')
require('./filters')

require('./pages/all')
require('./partials/all')

import LDPlugin from '../../shared/js//launch-darkly-plugin';
Vue.use(LDPlugin);

window.eventHub = new Vue()

var VueTouch = require('vue-touch')
Vue.use(VueTouch, {name: 'v-touch'})

import VueLazyLoad from 'vue-lazyload'
import Base from '../../desktop/js/components/base'
import SocialLogin from '../../desktop/js/components/social-login'
import { store } from '../../shared/js/state/SearchboxState'
import VueMask from 'v-mask'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

let isRetina = (
    window.devicePixelRatio > 1 ||
    (window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches)
);

Vue.use(VueLazyLoad, {
    filter: {
        progressive(listener, options) {
            let hasRetinaVersion = (listener.el.getAttribute('lazy-retina') === 'true');

            if (isRetina && hasRetinaVersion) {
                let url = listener.src;
                let directory = url.substring(0, url.lastIndexOf('/'));
                let filename = url.substring(url.lastIndexOf('/') + 1);
                listener.src = directory + '/2x/' + filename;
            }
        },
    }
});

Vue.use(VueMask);

Vue.component('searchbox', require('./components/Searchbox/Searchbox.vue').default);
Vue.component('searchbox-inline', require('./components/Searchbox/SearchboxInline.vue').default);
Vue.component('searchbox-hotel', require('./components/Searchbox/SearchboxHotel.vue').default);
Vue.component('accordion', require('./components/Accordion.vue').default);

Vue.mixin({
    methods: {
        trackEventGlobal(categoty, action = '', label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': categoty,
                'EventAction': action,
                'EventLabel': label
            });
        },

        // ToDO: can be replaced with lot.baseRateStr in 100 days after release
        getBaseRate(lot) {
            return typeof lot.baseRateStr !== 'undefined'
                ? lot.baseRateStr
                : '$' + lot.baseRate;
        },
    }
});

new Vue({
    store: store,
    mixins: [Base, SocialLogin],

    data() {
        return {
            id: null,
            role: 'guest',
            showOffCanvas: false
        }
    },

    mounted() {
        this.initBase();
        this.initSocialLoginButtons();

        let self = this
        this.$root.$on('session-received', function (data) {
            let login = data.login

            self.id = login.id
            self.role = login.role
        })

	    setTimeout(() => {
		    if (typeof initLoad === "function") {
			    initLoad()
		    }
	    }, 500)
    },

    methods: {
        onPage(page) {
            return window.location.href.includes(page)
        },
        notOnPage(pages) {
            if (window.location.href.includes('/help') || window.location.href.includes('/reservations')) {
                return false
            } else {
                return true
            }
        },
        goToPage(page){
	        window.location.href = page
        }
    }
}).$mount('#app');
