<template>
    <div id="searchbox_inline">
        <div class="searchbox">

            <!-- TABS -->
            <div class="row tabs">
                <div class="tab" :class="{'active': currentTab == 'parking'}" @click="setTab('parking')">Airport Parking Only</div>
                <div class="tab" :class="{'active': currentTab == 'hotel'}" @click="setTab('hotel')">Hotel & Parking Package</div>
                <!--<div class="tab" :class="{'active': currentTab == 'shuttle'}" @click="setTab('shuttle')" v-if="showSf">Shuttle Transport</div>-->
            </div>

            <!-- FIELDS -->
            <form action="" method="post" autocomplete="off" ref="searchboxForm">
                <display-apr v-if="currentTab == 'parking' && (passedOption == 'APR' && !isEditMode)"></display-apr>
                <display-psf v-if="currentTab == 'hotel' && (passedOption == 'PSF' && !isEditMode)"></display-psf>
                <option-apr v-if="currentTab == 'parking' && (isEditMode || passedOption == 'PSF')" style="max-width: 1000px;"></option-apr>
                <option-psf v-else-if="currentTab == 'hotel' && (isEditMode || passedOption == 'APR')" style="max-width: 1100px;"></option-psf>
                <!--<option-sf v-else-if="currentTab == 'shuttle'"></option-sf>-->
            </form>

        </div>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin";
    import RequestMixin from "./Requests.js";
    import OptionAPR from "./options/OptionsAPR.vue";
    import OptionPSF from "./options/OptionsPSF.vue";
    import OptionSF from "./options/OptionsSF.vue";
    import DisplayAPR from "./display/DisplayAPR.vue";
    import DisplayPSF from "./display/DisplayPSF.vue";

    export default {
        mixins: [ SearchboxMixin, RequestMixin ],
        components: {
            'option-apr': OptionAPR,
            'option-psf': OptionPSF,
            'option-sf': OptionSF,
            'display-apr': DisplayAPR,
            'display-psf': DisplayPSF,
        },
        data(){
            return {
                type: 'searchbox-inline',
                isEditMode: false,
                showDates: false,
            }
        },
        watch:{
            'query.date1': function(val) {
                this.showDates = true;
            },
        },
        mounted(){
            this.initSearchboxForm()
            if(apr.search_apr.search.data.token) {
                this.setData(apr.search_apr);
            }else if(apr.search_psf.search.data.token) {
                this.setData(apr.search_psf);
            }
        },
        methods: {
            setData(data){
                let search = {
                    id: data.search.data.token,
                    airport: {
                        city: data.search.data.airport.data.name,
                        code: data.search.data.airport.data.code,
                        type: data.search.data.airport.data.type,
                        latitude: data.search.data.airport.data.geo.latitude,
                        longitude: data.search.data.airport.data.geo.longitude,
                    },
                    date1: data.search.data.checkindate.date_format_2,
                    date2: data.search.data.checkoutdate.date_format_2,
                    dates: data.search_details ? data.search_details.dates : null,
                    package: data.search_details ? data.search_details.package_type : data.search.data.package,
                    guests: data.search.data.guests,
                    rooms: data.search.data.rooms
                };
                let tab = data.search.data.package == 'APR' ? 'parking' : 'hotel';
                this.$store.dispatch('updateTab', tab)
                this.$store.dispatch('updateSession', search);
            },
            setTab(tab){
                if(tab == 'parking') {
                    this.$store.dispatch('updatePackage', 'APR');
                }else{
                    this.$store.dispatch('updatePackage', this.session.package);
                }
                this.$store.dispatch('updateTab', tab);
            },
            getPageHeading(tagName) {
                if (this.currentTab == 'hotel') {
                    return this.headings.psf[tagName]
                }
                if (this.currentTab == 'shuttle') {
                    return this.headings.sf[tagName]
                }
                return this.headings.apr[tagName]
            },

            showDatesInit(){
                setTimeout(() => {
                    $('input[name="checkindate"]').click()
                }, 10)
            },
        }
    };
</script>
