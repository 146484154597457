<template>
    <section class="airport-subscribe">
        <form action="" method="post">
            <h2>Subscribe for exclusive deals</h2>
            <p>Sign up for our email list to get secret coupons!</p>
            <transition name="fade" mode="out-in">
                <div class="col d-flex flex-column justify-content-center" v-if="!isSubscribed" key="subscribe">
                    <div class="email">
                        <input
                            type="email"
                            v-model="email"
                            name="email"
                            placeholder="Enter your email"
                            autocomplete="off"
                            required
                        />
                    </div>
                    <button type="submit" class="btn-brand-primary">
                        Subscribe <i class="apr-arrow-right" aria-hidden="true"></i>
                    </button>
                    <span v-if="error" class="subscribe-error">{{ error }}</span>
                </div>
                <div class="col d-flex flex-column justify-content-center" v-else key="subscribed">
                    <div class="success">
                        You have been subscribed
                        <i class="far fa-thumbs-up" aria-hidden="true"></i>
                    </div>
                </div>
            </transition>
        </form>
    </section>
</template>

<script>
export default {
    data: function () {
        return {
            email: null,
            isSubscribed: false,
            error: false,
        };
    },

    mounted() {
        this.initSearchboxForm();
    },

    watch: {
        email(val, oldVal) {
            if (val != oldVal) this.error = false;
        },
    },

    methods: {
        initSearchboxForm() {
            let self = this;
            let requesting = false;
            let $form = $(this.$el).find('form');

            $form
                .parsley()
                .on('form:submit', function () {
                    $form.find('input').each(function () {
                        $(this).parsley().removeError('custom-error');
                    });
                    return false;
                })
                .on('form:success', function (formInstance) {
                    if (requesting) {
                        return false;
                    }
                    requesting = false;

                    self.$http.post('/newsletters/subscribe', { email: self.email }).then(
                        (response) => {
                            self.isSubscribed = true;
                            dataLayer.push({
                                event: 'GaEvent',
                                EventCategory: 'Newsletter',
                                EventAction: 'subscribing',
                            });
                        },
                        (response) => {
                            self.error = 'We have encountered an problem. Try again later.';
                            requesting = false;
                        }
                    );

                    return false;
                });
        },
    },
};
</script>

<style lang="scss">
.airport-subscribe {
    padding: 56px 24px 60px;
    background: #103c53;
    font-size: 1rem;
    color: white;
    text-align: center;

    h2 {
        color: #fff;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 2rem;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 40px;
    }

    form {
        input {
            height: 50px;
            width: 100%;
            padding: 12px 16px;
            color: #0E242B;
            background-color: white;
            border-radius: 8px;
            border: 1px solid #B7BDBF;

            &::placeholder {
                color: rgba(41, 63, 77, 0.18);
            }
            &:focus {
                border: 1px solid #0A475A;
                outline: 2px solid #0A475A;
                box-shadow: none;
            }
        }
        button {
            margin-top: 12px;
        }

        .parsley-errors-list > li,
        .subscribe-error {
            font-size: 1rem;
            color: #D41818;
            padding-left: 0;
            text-align: center;
        }
        .subscribe-error {
            margin-top: 6px;
        }
    }
}
</style>
