import FSformater from '../../mixins/FSFormater'

Vue.component('pages-flightstatus-information', {

    mixins: [ FSformater ],

    data() {
        return {
            flight: apr.flight,
            isLoading: true,
            departure: {
                time: null,
                label: null,
            },
            arrival: {
                time: null,
                label: null,
            },
            flightDuration: 0,
            elapsedFlightTime: 0,
        }
    },

    mounted() {
        this.calculateDepartureTime()
        this.calculateArrivalTime()
        this.flightDuration = this.getFlightDuration()
        this.elapsedFlightTime = this.getElapsedFlightTime()
    },

    methods: {
        getDelay() {
            if (this.flight.status == 'L') {
                return this.flight.arrivalGateDelayMinutes
            }
            return this.flight.departureGateDelayMinutes
        },

        calculateDepartureTime() {
            this.departure.time = moment.utc(this.flight.departureTime)
            this.departure.label = 'Scheduled'
            if (this.actualDeparture()) {
                this.departure.time = moment.utc(this.actualDeparture())
                this.departure.label = 'Actual'
            } else if (this.estimatedDeparture()) {
                this.departure.time = moment.utc(this.estimatedDeparture())
                this.departure.label = 'Estimated'
            }
        },

        actualDeparture() {
            if (this.flight.actualGateDeparture) {
                return this.flight.actualGateDeparture
            }
            return this.flight.actualRunwayDeparture
        },

        estimatedDeparture() {
            if (this.flight.estimatedGateDeparture) {
                return this.flight.estimatedGateDeparture
            }
            return this.flight.estimatedRunwayDeparture
        },

        calculateArrivalTime() {
            this.arrival.time = moment.utc(this.flight.arrivalTime)
            this.arrival.label = 'Scheduled'
            if (this.actualArrival()) {
                this.arrival.time = moment.utc(this.actualArrival())
                this.arrival.label = 'Actual'
            } else if (this.estimatedArrival()) {
                this.arrival.time = moment.utc(this.estimatedArrival())
                this.arrival.label = 'Estimated'
            }
        },

        actualArrival() {
            if (this.flight.actualGateArrival) {
                return this.flight.actualGateArrival
            }
            return this.flight.actualRunwayArrival
        },

        estimatedArrival() {
            if (this.flight.estimatedGateArrival) {
                return this.flight.estimatedGateArrival
            }
            return this.flight.estimatedRunwayArrival
        },

        getFlightDuration() {
            return moment.duration(this.arrival.time.diff(this.departure.time)).asMinutes()
        },

        getElapsedFlightTime() {
            let minutes = 0;
            if (this.flight.status == 'A' || this.flight.status == 'R') {
                let momentUtc = moment.utc();
                if (momentUtc > this.departure.time && momentUtc < this.arrival.time) {
                    minutes = Math.ceil(moment.duration(moment.utc().diff(this.departure.time)).asMinutes());
                }
            } else if (this.flight.status == 'L') {
                minutes = this.flightDuration
            }
            return minutes;
        },

        getRemainingFlightTime(){
            let minutes = 0;
            if (this.flight.status == 'A' || this.flight.status == 'R') {
                let momentUtc = moment.utc();
                if (momentUtc > this.departure.time && momentUtc < this.arrival.time) {
                    minutes = this.flightDuration - this.elapsedFlightTime
                }
            }
            return minutes
        },
    }

});
