Vue.component('page-customer-profile', {

    data: function () {
        return {
            customer: apr.customer,
            password: null,
            password_confirmation: null,
            newsletter: null,

            successMessage: null,
            errorMessage: null,
        }
    },

    mounted() {
        this.initModalForm()
    },

    watch: {},

    methods: {
        initModalForm(){
            let self = this
            let requesting = false
            let $form = $(this.$el).find("form")

            $form.parsley().on("form:submit", function () {
                $form.find("input").each(function () {
                    $(this).parsley().removeError('custom-error');
                });
                return false;

            }).on('form:success', function (formInstance) {
                if (requesting) {
                    return false;
                }
                requesting = true;
                self.successMessage = null
                self.errorMessage = null

                self.$http.put('/customer/profile', self.getFormData()).then(response => {
                    self.successMessage = response.data.message
                    requesting = false;

                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        if (errorName == 'errors') {
                            self.errorMessage = self.filters.formatMessage(errors[errorName])
                        }

                        let $field = $form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    requesting = false;
                });

                return false;
            });
        },

        getFormData() {
            return {
                email: this.customer.email,
                firstname: this.customer.firstname,
                lastname: this.customer.lastname,
                zipcode: this.customer.zipcode,
                mobile: this.customer.mobile,
                phone: this.customer.phone,

                password: this.password,
                password_confirmation: this.password_confirmation,
                newsletter: this.newsletter,
            }
        }
    }
});
