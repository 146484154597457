<template>
	<section id="flightstatus-search">
		<div class="container">

			<!-- Tabs -->
			<div class="tabs">
				<div class="tab" :class="{ 'active': tab === 'airline' }" @click.prevent="selectTab('airline')">Track by Flight</div>
				<div class="tab" :class="{ 'active': tab === 'airport' }" @click.prevent="selectTab('airport')">Track by Airport</div>
			</div>

			<!-- Flight Departure Airport -->
			<div class="item airport" :class="{ 'error': showErrors && !airport.departure.code }" v-if="tab === 'airport'">
				<i class="fas fa-plane item-icon"></i>
				<label>Departure Airport</label>
                <algolia-typeahead
                        id="airportSelect"
                        placeholder="Enter Airport"
                        class="flightstatus-page-typeahead"
                        v-on:typeahead-change="setDepartureAirport">
                </algolia-typeahead>
				<div class="error-message">Please select an airport.</div>
			</div>

			<!-- Flight Arrival Airport -->
			<div class="item airport" :class="{ 'error': showErrors && !airport.arrival.code }" v-if="tab === 'airport'">
				<i class="fas fa-plane item-icon"></i>
				<label>Arrival Airport</label>
                <algolia-typeahead
                        id="airportSelect2"
                        placeholder="Enter Airport"
                        class="flightstatus-page-typeahead"
                        v-on:typeahead-change="setArrivalAirport">
                </algolia-typeahead>
				<div class="error-message">Please select an airport.</div>
			</div>

			<!-- Flight Airline -->
			<div class="item airline" :class="{ 'error': showErrors && !airline.selected.code }" v-if="tab === 'airline'">
				<i class="fas fa-plane item-icon"></i>
				<label>Airline</label>
				<span class="null-text" v-if="!airline.selected.code">Select Airline</span>
				<select name="airline" id="airlineSelect" :class="{ 'null': !airline.selected.code }" v-model="airline.selected">
					<option v-for="item in airlines" :value="item">{{ item.name }} ({{ item.code.toUpperCase() }})</option>
				</select>
			</div>

			<!-- Flight Departure Date -->
			<div class="item departure-date" :class="{ 'error': showErrors && !datepicker_value }"  @click.prevent="showDatepicker()">
				<i class="far fa-calendar item-icon"></i>
				<label v-if="tab === 'airline'">Flight Departure Date</label>
				<label v-if="tab === 'airport'">Departure Date</label>
				<input type="text" :id="[variable + '-date']" placeholder="Select Date"
					   v-model="datepicker_value" :name="variable" readonly/>
				<div class="error-message">Please select a date.</div>
			</div>

			<datepicker
					:ref="'datepicker_' + variable"
					:variable="variable"
					:show-close="true"
					:show-clear="true"
					:single-date="true"
					:autoclose="false"
					:min-date="minDate"
					:max-date="maxDate"
					:pre-select-min="false"
			></datepicker>

			<!-- Flight Number -->
			<div class="item flight-number" :class="{ 'error': showErrors && !flight_number }" v-if="tab === 'airline'">
				<i class="fas fa-hashtag item-icon"></i>
				<label>Flight Number</label>
				<div class="flightnumber">
					<input class="w-100 border-0 rounded" type="tel" placeholder="Enter flight number" v-model="flight_number">
				</div>
				<div class="error-message">Please enter flight number.</div>
			</div>

			<!-- Submit -->
			<div class="item">
				<div class="submit">
					<button type="submit" class="btn btn-secondary d-block w-100 submit px-5" @click.prevent="search(false)">
						<span v-if="!working">View Flight Status</span>
						<span v-else class="fa fa-spinner fa-spin"></span>
					</button>
				</div>
			</div>

		</div>
	</section>
</template>
<script>
	import FlightStatusMixin from '../../../../shared/js/FlightStatusMixin'
	import AlgoliaTypeahead from '../Searchbox/components/AlgoliaTypeahead'
	import Datepicker from 'vue-datepicker-apr'

	export default {
		mixins: [ FlightStatusMixin ],
		components: { AlgoliaTypeahead, Datepicker },

		data() {
			return {
				searchboxType: 'multi',
				tab: 'airline',
				variable: 'departure',
			}
		},
	}
</script>
