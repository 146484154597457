<template>
    <input type="checkbox"/>
</template>

<script>
    import merge from 'merge'

    if (!jQuery().bootstrapToggle) {
        require('bootstrap-toggle')
    }

    const defaults = {}

    export default {
        defaults,
        props: {
            value: Number | Boolean,
            options: {
                type: Object,
                default: () => ({})
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {updating: false};
        },
        computed: {
            $$el() {
                return jQuery(this.$el)
            }
        },
        watch: {
            value(newValue) {
                if (this.updating) {
                    return;
                }

                this.$$el.bootstrapToggle(newValue ? 'on' : 'off');
            },
            disabled(newValue) {
                this.$$el.bootstrapToggle(newValue ? 'disable' : 'enable');
            }
        },
        mounted() {
            let self = this
            if (this.value) {
                this.$el.checked = true
            }
            this.$$el.bootstrapToggle(merge.recursive(true, defaults, this.options))
            this.$$el.change(() => {
                this.updating = true;
                this.$emit('input', this.$$el.prop('checked'));
                this.$nextTick(() => this.updating = false);
            })

            $(document).on('click.bs.toggle', 'div[data-toggle^=toggle]', function(e) {
                self.$emit('click', self.$$el.prop('checked'))
            })
        },
        beforeDestroy() {
            this.$$el.bootstrapToggle('destroy')
            this.$$el.off('change')
        }
    }
</script>