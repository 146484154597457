import Stars from "./../../components/stars";
import SearchFilter from "../../components/search-filter";
import Helpers from "../../../../shared/helpers";

Vue.component('page-psf-search', {

    components: {
        Stars,
    },

    mixins: [SearchFilter, Helpers],

    data: function () {
        return {
            pageType: 'PSF',
            pageLoaded: false,
            airport: {},

            isCruiseport: (_.get(window, 'apr.search_psf.airport.type') === 'CruisePort'),
            dates: _.get(window, 'apr.search_psf.search_details.dates'),
            searchbox: _.get(window, 'apr.search_psf.search.data'),

            days_between: 0,

            search: [],
            soldout: [],

            apr_results: 0,
            apr_filtered: 0,
            psf_results: 0,
            apr_soldout_results: 0,

			apr_results_link: apr.apr_results_link,

            reservationProcessError: _.get(window, 'apr.exp_error'),
            reservationProcessStatus: _.get(window, 'apr.exp_status'),
            expediaHotelName: _.get(window, 'apr.exp_hn'),

            filter_list: [],
            filter_options: [],
            sort_option: 'popular-asc',

            error_dialog: {
                show: false,
                bold: `${apr.soldout} is <span class="highlight">sold out.</span>`,
                normal: 'Hotels are selling out quick for your travel dates.<br/>Take a look at some other great options',
                excerpt: 'Here are some other great options.'
            },
        }
    },

    mounted() {
        // filter functions
        this.detectHideFilter();
        this.selectSortByOption('PSF');

        this.airport = apr.search_psf.airport

        this.days_between = apr.search_psf.search.data.days;

        this.apr_results = 0
        this.psf_results = apr.search_psf.results ? apr.search_psf.meta.psf_count : 0;
        this.apr_soldout_results = _.size(apr.search_apr.soldout)

		if(apr.search_psf.meta){
        	this.filter_list = apr.search_psf.meta.options
		}

        this.filterProducts()
        this.showMap()

        if (apr.soldout) {
            this.error_dialog.show = true
        }

        // for back button functionality
        window.addEventListener("popstate", function (event) {
            if (event.state && event.state.action === "product") {
                window.location.reload();
            }
        }, false);
    },

    methods: {
		showEmailModal(){
			$('#Email_Modal').modal('show')
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Saved Search', 'EventAction': 'Modal - Opened', 'EventLabel': ''})
        },

		sendSearchResults(){
			let email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(email_regex.test(this.email)){
				this.emailSending = true
                this.$http.post(apr.saved_search_url, {'email_address': this.email, 'token': apr.search_psf.search.data.token, 'optin': this.emailOptIn }).then((response) => {
                    this.emailErrorMessage = null
                    this.email = null
					this.emailSending = false
                    dataLayer.push({'event': 'GaEvent', 'EventCategory':'Saved Search', 'EventAction': 'Modal - Submitted', 'EventLabel': ''})
                    $('#Email_Modal').modal('hide')
					swal({
						title: "Email Sent!",
						type: "success",
						timer: 2000,
						showConfirmButton: false
					});
                }, (response) => {
                    this.emailErrorMessage = 'Please supply a valid email address'
                })
			}else{
                this.emailErrorMessage = 'Please supply a valid email address'
			}
		},

        hideEmailModal() {
            this.emailErrorMessage = null
            this.email = null
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Saved Search', 'EventAction': 'Modal - Closed', 'EventLabel': ''})
            $('#Email_Modal').modal('hide')
        },

		productClick(hotel, index){
			this.ecommercePsfTracking(hotel, index);
            $(`.lots__lot[data-lotid='${hotel.id}']`).css("background-color", "#eee");
        },

        filterProducts() {
            // only APR

            let self = this

            if (this.filter_options.length > 0) {
                this.search = _.filter(apr.search_psf.results, function (o) {
                    if (o.provider == 'APR') {
                        let result = false

                        for (var i = 0, len = self.filter_options.length; i < len; i++) {
                            if (o.options.indexOf(self.filter_options[i]) > -1) {
                                result = true
                            }else{
                                self.apr_filtered++;
                            }
                        }
                        return result
                    }
                })

            } else {
                this.search = apr.search_psf.hotels
            }

            if ( ! this.search) {
                this.trackEvent('No products available')
            }

            this.sortList()
        },

        sortList() {
            let [type, direction] = this.sort_option.split("-")

            if (type != 'popular') {
                if (type == 'rating') {
                    this.search = _.orderBy(this.search, function (o) {
                        return o.ratings.average_rating
                    }, direction)

                } else if (type == 'distance') {
                    this.search = _.orderBy(this.search, [type], direction)

                } else if (type == 'rate') {
                    let [lots, hotels] = _.partition(this.search, ['provider', 'APR'])

                    this.search = _.orderBy(this.search, [type], direction)
                }
            }
        },

        showMap(){
            let self = this
            let markerArray = [];

            $(document).on('shown.bs.modal', '#map-modal', function () {
                $("#map_canvas").gmap3({
                    scrollwheel: false,
                    center: [Number(self.airport.geo.latitude), Number(self.airport.geo.longitude)],
                    zoom: 12,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    navigationControl: true,
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                        style: "SMALL"
                    },
                    options: {
                        styles: [
                            {featureType: "poi", elementType: "labels", stylers: [{"visibility": "off"}]}
                        ]
                    }

                })
                    .marker(self.getMarkers())
                    .then(function (generatedMarkers) {
                        markerArray = generatedMarkers; // save markers for future deletion
                    })
                    .on('click', function (marker) {
                        $("#map_marker").empty();
                        let card = $('*[data-lotid="' + marker.id + '"]').clone();
                        $("#map_marker").html(card);
                    })
                    .fit();
            });

            // remove all markers
            $(document).on('hidden.bs.modal', '#map_canvas', function () {
                for (var i = 0; i < markerArray.length; i++) {
                    markerArray[i].setMap(null);
                }
                $("#map_marker").empty();
            });

            return false;
        },

        getMarkers() {
            this.filterProducts()

            this.markers = []
            this.getAirportMarker()

            for (let lot of Array.from(this.search)) {
                let marker = this.getLotMarker(lot);
                this.markers.push(marker)
            }

            return this.markers;
        },

        getAirportMarker() {

            this.markers = [{
	            type: this.airport.type === 'CruisePort' ? 'cruise' : 'airport',
                position: {lat: Number(this.airport.geo.latitude), lng: Number(this.airport.geo.longitude)},
                icon: {
	                url: apr.storage_url + '/img/icons/' + (this.airport.type === 'CruisePort' ? 'cruise-pin-new-grey.png' : 'airport-pin-new-black.png'),
					scaledSize: new google.maps.Size(45, 36),
					anchor: new google.maps.Point(23, 36)
				},
                animation: google.maps.Animation.FADE,
                draggable: false
            }]
        },

        getLotMarker(lot) {
            let props = {
                position: {lat: Number(lot.geo.latitude), lng: Number(lot.geo.longitude)},
                animation: google.maps.Animation.FADE,
                draggable: false,
                flat: true,

                icon: {
                    // url: apr.storage_url + '/img/icons/hotel-blank-pin-new.png',
                    url: 'https://s3.amazonaws.com/apr_cdn/img/icons/hotel-blank-pin-new.png',
                    scaledSize: new google.maps.Size(45, 36),
                    anchor: new google.maps.Point(23, 36),
                    labelOrigin: new google.maps.Point(22, 14)
                },
                label: {
                    text: "$" + Math.round(lot.rate),
                    color: 'white',
                    fontSize: '0.75rem'
                },
                id: lot.hotel_id,
            };

            //console.log(props)
            return props;
        },

        notifySearchboxOpen(){
            console.log('Searchbox Open')
        },

        trackEvent(name) {
            dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Search Results', 'EventAction': name, 'EventLabel': ''});
		},

        ecommercePsfTracking(hotel, index) {
            //dataLayer - productClick
            let providerName = _.get(hotel, 'provider');
            let category = his.getLotCategory(providerName);
            let variant = typeof(hotel.available) != 'undefined' && hotel.available === true
                ? "available"
                : "sold out";
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': 'PSF Search Results'},
                        'products': [{
                            'name': `PSF ${this.airport.code} ${hotel.name}`,
                            'id': this.airport.code,
                            'price': hotel.rate,
                            'brand': '',
                            'category': category,
                            'variant': variant,
                            'position': index
                        }]
                    }
                }
            })
        }
    }
});
